import React from 'react';
import {
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
  Checkbox,
  FormControlLabel,
  Button,
  Skeleton,
} from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';

export const PermissionsForm = ({
  role,
  initialValues,
  handleSubmit,
  selectedModuleIndex,
  handleTabChange,
  permissionsData,
  classes,
  isLoading,
}: any) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    onSubmit={handleSubmit}>
    {({ values, isSubmitting }) => (
      <Form style={{ width: '100%' }}>
        <Grid container item xs={12}>
          <Typography
            variant="h4"
            sx={{ fontSize: '12px', fontWeight: 600, ml: 1 }}>
            {role?.name}
          </Typography>
          <Grid
            container
            item
            xs={12}
            mt={2}
            className={classes.selectPermissionsCard}>
            <Grid item xs={3} className="tabs">
              {isLoading ? (
                <Box>
                  <Skeleton width="95%" />
                  <Skeleton width="95%" />
                  <Skeleton width="95%" />
                  <Skeleton width="95%" />
                  <Skeleton width="95%" />
                </Box>
              ) : (
                <Tabs
                  orientation="vertical"
                  value={selectedModuleIndex}
                  onChange={handleTabChange}
                  variant="scrollable"
                  aria-label="Modules"
                  indicatorColor="secondary"
                  textColor="inherit"
                  sx={{ borderRight: 1, borderColor: 'divider' }}>
                  {permissionsData?.length > 0
                    ? permissionsData?.map((module, index) => (
                        <Tab
                          label={module.moduleName}
                          key={module.id}
                          className={`${
                            selectedModuleIndex === index
                              ? classes.activeTab
                              : ''
                          } allTabs`}
                        />
                      ))
                    : <Typography>No Module</Typography>}
                </Tabs>
              )}
            </Grid>
            <Grid item xs={9} className={classes.permissionBoxesWrapper}>
              {isLoading ? (
                <Box sx={{ paddingTop: '0px !important' }}>
                  <Skeleton width="25%" />
                  <Box display="flex" flexWrap="wrap">
                    <Box width="30%" display="flex" flexWrap="nowrap" mt={1}>
                      <Skeleton width="5%" sx={{ mr: 1 }} />
                      <Skeleton width="25%" />
                    </Box>
                    <Box width="30%" display="flex" flexWrap="nowrap" mt={1}>
                      <Skeleton width="5%" sx={{ mr: 1 }} />
                      <Skeleton width="25%" />
                    </Box>
                    <Box width="30%" display="flex" flexWrap="nowrap" mt={1}>
                      <Skeleton width="5%" sx={{ mr: 1 }} />
                      <Skeleton width="25%" />
                    </Box>
                    <Box width="30%" display="flex" flexWrap="nowrap" mt={1}>
                      <Skeleton width="5%" sx={{ mr: 1 }} />
                      <Skeleton width="25%" />
                    </Box>
                    <Box width="30%" display="flex" flexWrap="nowrap" mt={1}>
                      <Skeleton width="5%" sx={{ mr: 1 }} />
                      <Skeleton width="25%" />
                    </Box>
                  </Box>
                </Box>
              ) : (
                <>
                  {permissionsData?.length > 0
                    ? permissionsData[selectedModuleIndex]?.subModule.map(
                        (subMod) => (
                          <Box
                            key={subMod._id}
                            sx={{ paddingTop: '0px !important' }}>
                            <Typography
                              variant="h4"
                              gutterBottom
                              sx={{ textTransform: 'capitalize' }}>
                              {subMod.subModuleName} Permissions
                            </Typography>

                            <FieldArray
                              name="permissions"
                              render={(arrayHelpers) => (
                                <>
                                  {subMod.permissions.map((permission) => (
                                    <FormControlLabel
                                      key={permission._id}
                                      control={
                                        <Checkbox
                                          size="small"
                                          checked={values.permissions.includes(
                                            permission._id
                                          )}
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              arrayHelpers.push(permission._id);
                                            } else {
                                              const idx =
                                                values.permissions.indexOf(
                                                  permission._id
                                                );
                                              arrayHelpers.remove(idx);
                                            }
                                          }}
                                        />
                                      }
                                      label={permission.name}
                                    />
                                  ))}
                                </>
                              )}
                            />
                          </Box>
                        )
                      )
                    : 'No Sub modules'}
                </>
              )}
              <Grid container spacing={2} mt={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="reset"
                    disabled={isSubmitting || !permissionsData?.length}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting || !permissionsData?.length}>
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    )}
  </Formik>
);
