import { isUndefined } from 'lodash';
import axios, { getRequest, patchRequest, postRequest } from 'src/utils/axios';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';

class ContactsApi {
  async getAll(
    page: number,
    limit: number,
    search: any,
    isActivated: boolean | null,
    organizationID?: string | null,
    columns?: any,
    sort?: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload = {
        page,
        pageSize: limit,
        search,
        isActive: isActivated,
        organizationId: organizationID,
        column: columns,
        sort: sort === 'asc' ? '1' : '-1',
      };
      Object.keys(payload).forEach((key) => {
        if (
          payload[key] === null ||
          payload[key] === undefined ||
          payload[key] === ''
        ) {
          delete payload[key];
        }
      });

      axios
        .get(`${process.env.REACT_APP_CASES}/contact`, { params: payload })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async bulkDeactivate(checkedIds: Array<number>): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload: any = {
        list: checkedIds,
      };
      axios
        .patch(
          `${process.env.REACT_APP_EXTERNAL_USER_URL}/users/bulk-deactivate?isExternal=true`,
          payload
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error('internal Server Error'));
        });
    });
  }

  async create(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/contact`,
        payload,
        successFunction,
        errorFunction,
      };
      postRequest(data);
    });
  }

  async getById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/contact/${id}`,
        successFunction,
        errorFunction,
      };
      getRequest(data);
    });
  }

  async getAssignedProjects(userId: string): Promise<any> {
    console.log('userId', userId);

    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/project/assigned_project/${userId}`,
        successFunction,
        errorFunction,
      };
      getRequest(data);
    });
  }

  async updateById(id: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const successFunction = (response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          resolve(response);
        }
      };
      const errorFunction = (error) => {
        reject(error);
      };
      const data = {
        url: `${process.env.REACT_APP_CASES}/contact/${id}`,
        payload,
        successFunction,
        errorFunction,
      };
      patchRequest(data);
    });
  }

  async updateStatusById(id: number, status: boolean) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.REACT_APP_CASES}/contact/${id}/${status}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async allowedProjects({ userId, projectIds }: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${process.env.REACT_APP_CASES}/project/assign_project/${userId}`,
          { projectIds },
          {
            params: { userId },
          }
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getAllProjectsByOrganization(organizationId): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/project`, {
          params: {
            organizationId,
          },
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }
}

export const contactsApi = new ContactsApi();
