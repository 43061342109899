/* eslint-disable no-bitwise */
const hexToRgbA = (hex: string, opacity: number = 1) => {
  let rgba;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    rgba = hex.substring(1).split('');
    if (rgba.length === 3) {
      rgba = [rgba[0], rgba[0], rgba[1], rgba[1], rgba[2], rgba[2]];
    }
    rgba = `0x${  rgba.join('')}`;
    return (
      `rgba(${
      [(rgba >> 16) & 255, (rgba >> 8) & 255, rgba & 255].join(',')
      },${
      opacity
      })`
    );
  }
  throw new Error('Bad Hex');
};
export default hexToRgbA;
