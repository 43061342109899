/* eslint-disable prefer-rest-params */
import i18next from 'i18next';
import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';

type ReportingPayloadType = {
  billingAccountId?: string;
  page: number;
  pageSize: number;
  organizationId: string;
  to: string;
  from: string;
  sprintId: string;
};

class ReportingApi {
  async getAllReportings({
    billingAccountId,
    page,
    pageSize,
    organizationId,
    to,
    from,
    sprintId,
  }: ReportingPayloadType): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_CASES}/cases/billableStatistics?billingAccountId=${billingAccountId}&page=${page}&pageSize=${pageSize}&to=${to}&from=${from}&organizationId
=${organizationId}&sprintId=${sprintId}`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getOrganizations(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/auto-complete/organizations`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response)) {
            resolve(response);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }

  async getBillingAccounts({ orgId }: { orgId: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_CASES}/auto-complete/billingAccount/${orgId}`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response)) {
            resolve(response);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }

  async getOrganizationsSprints({
    orgId,
  }: {
    search?: string;
    orgId: string;
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_CASES}/sprint/getSprintByOrganization/${orgId}`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response)) {
            resolve(response);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }

  async billableStatisticsExport({
    billingAccountIds,
    from,
    to,
    sprintId,
  }: {
    billingAccountIds: string;
    from: string;
    to: string;
    sprintId: string;
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/cases/billableStatisticsExport`, {
          params: {
            billingAccountId: billingAccountIds,
            from,
            to,
            sprintId
          },
          responseType: 'blob',
        })
        .then((response) => {
          if (!isUndefined(response?.data)) {
            resolve(response?.data);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        })
        .catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
    });
  }
}

export const reportingApi = new ReportingApi();
