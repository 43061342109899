/* eslint-disable implicit-arrow-linebreak */
import { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import ModulesLayout from './components/ModulesLayout';
import RolesLayout from './components/RolesLayout';
import DashboardLayout from './components/dashboard/DashboardLayout';
import MeetingsSection from './components/meetings/MeetingsSection';
import NoteSection from './components/notes/NoteSection';
// import WhiteLabelSection from './components/organization/white_label/WhiteLabelSection';
import ContactsSection from './components/organization/contacts/ContactsSection';
import ProjectSection from './components/organization/projects/ProjectSection';
import ProjectsSubTabs from './components/organization/projects/ProjectsSubTabs';
import CasesViewByProjects from './components/organization/projects/cases/CasesViewByProjects';
import EditUser from './components/organization/projects/users/EditUser';
import ViewUsers from './components/organization/projects/users/ViewUsers';
import EditCases from './pages/cases/EditCases';

import BulkChange from './pages/cases/BulkChange';
import DeclinedCases from './pages/cases/DeclinedCases';
import EditCasesByProject from './components/organization/projects/cases/EditCaseByProject';
import ViewCases from './pages/cases/ViewCases';
import Overview from './pages/dashboard/Overview';
import AddEmployees from './pages/employees/addNew';
import EditEmployees from './pages/employees/edit';
import AddOrganization from './pages/organization/addNew';
import OrganizationDetails from './pages/organization/details/OrganizationDetails';
import ProductInformationManagement from './pages/pim';

import PermissionNew from './pages/settings/permissions/addNew';
import PermissionEdit from './pages/settings/permissions/edit';
import PermissionsListView from './pages/settings/permissions/listView';

import PermissionsLayout from './pages/settings/layouts/PermissionsLayout';
import ConfigurationLayout from './pages/settings/layouts/ConfigurationLayout';
import ConfigurationListView from './pages/settings/configuration/listView';
import ConfigurationAdd from './pages/settings/configuration/add';
import ConfigurationEdit from './pages/settings/configuration/edit';
import { Navigate } from 'react-router';
// import Planner from './pages/Planner';
import ViewRequests from './pages/requests/ViewRequests/index';
import { EditRequest } from './pages/requests/EditRequests/index';
import TestFlow from './pages/flows/TestFlow';
import FlowLayout from './pages/settings/layouts/FlowLayout';
import StatusFlowListView from './pages/settings/status-flow/listView';
import StatusFlowAdd from './pages/settings/status-flow/add';
import StatusFlowEdit from './pages/settings/status-flow/edit';
import ItsmFlowListView from './pages/settings/itsm-flow/listView';
import ItsmFlowAdd from './pages/settings/itsm-flow/add';
import ItsmFlowEdit from './pages/settings/itsm-flow/edit';
// import ConsultingUnits from './pages/consulting/units/ListViewUnits';
// import ConsultingLogs from './pages/consulting/ViewLogs';
// import EditConsultingUnits from './pages/consulting/units/EditUnits';
import Consulting from './pages/consulting';
import CuDetails from './pages/consulting/units/CuDetails';
import { ClientSettingsMain } from './pages/clientSettings';
import Okrs from './pages/okr copy';
import Catalogs from './pages/pim/Catalogs';
import Categories from './pages/pim/Categories';
import OrderListing from './pages/oms/orderListing';
import QouteListing from './pages/oms/quoteListing';
import ViewSprints from './pages/sprints';
import ViewReleaseVersions from './pages/release-versions';
import LeavesQuota from './pages/leaves-quota';
import LeavesReporting from './pages/leaves-reporting';
import Reporting from './pages/settings/reporting';
import Teams from './pages/teams';
import GeneralSettings from './pages/generalSettings';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(
  lazy(() => import('./pages/authentication/PasswordRecovery'))
);
const PasswordReset = Loadable(
  lazy(() => import('./pages/authentication/PasswordReset'))
);

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const ListViewOrganization = Loadable(
  lazy(() => import('./pages/organization/listView'))
);
const ListViewEmployees = Loadable(
  lazy(() => import('./pages/employees/listView'))
);
const ListViewContacts = Loadable(
  lazy(() => import('./pages/contacts/listView'))
);
const AddContacts = Loadable(lazy(() => import('./pages/contacts/addNew')));
const EditContacts = Loadable(lazy(() => import('./pages/contacts/edit')));

const ListViewModules = Loadable(
  lazy(() => import('./pages/settings/modules/ListView'))
);
const ListViewRoles = Loadable(
  lazy(() => import('./pages/settings/roles/ListView'))
);
const ListBillingAccounts = Loadable(
  lazy(() => import('./pages/settings/billingAccounts'))
);
const AddRoles = Loadable(lazy(() => import('./pages/settings/roles/AddNew')));
const AddModules = Loadable(
  lazy(() => import('./pages/settings/modules/AddNew'))
);
const EditRoles = Loadable(
  lazy(() => import('./pages/settings/roles/EditView'))
);
const EditModules = Loadable(
  lazy(() => import('./pages/settings/modules/EditView'))
);
// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/AuthorizationRequired'))
);
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// eslint-disable-next-line import/no-mutable-exports
let routes: any[] = [];

routes = [
  {
    path: '',
    element: <Navigate to="erp/dashboard" />,
  },
  // ---> dashboard
  {
    path: 'erp',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" />,
      },
      {
        path: 'dashboard',
        element: <Overview />,
      },
      {
        path: 'okr',
        element: <Okrs />,
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'flow',
        element: <TestFlow />,
      },
    ],
  },
  // ---> cases
  {
    path: 'erp/cases',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <ViewCases />,
      },
      {
        path: 'declined-cases/:caseID',
        element: <EditCases />,
      },
      {
        path: ':caseID',
        element: <EditCases />,
      },
      {
        path: 'bulk-change/:projectName/:projectID',
        element: <BulkChange />,
      },
      {
        path: 'declined-cases',
        element: <DeclinedCases />,
      },
    ],
  },
  // ---> requests BulkChange
  {
    path: 'erp/reporting',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Reporting />,
      },
    ],
  },
  {
    path: 'erp/teams',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Teams />,
      },
    ],
  },
  {
    path: 'erp/internal-requests',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <ViewRequests />,
      },
      {
        path: ':requestID',
        element: <EditRequest />,
      },
    ],
  },
  {
    path: 'erp/leaves',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'quota',
        element: <LeavesQuota />,
      },
      {
        path: 'reporting',
        element: <LeavesReporting />,
      },
    ],
  },
  // PIM
  {
    path: 'pim',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'catalogues',
        element: <Catalogs />,
      },
      {
        path: 'catalogues/:catalogueName',
        element: <Categories />,
      },
      {
        path: 'catalogues/:catalogueName/:categoryID',
        element: <ProductInformationManagement />,
      },
    ],
  },
  // PIM
  {
    path: 'oms',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'orders',
        element: <OrderListing />,
      },
      {
        path: 'quotes',
        element: <QouteListing />,
      },
    ],
  },
  // ---> settings
  {
    path: 'erp/settings',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'modules',
        element: <ModulesLayout />,
        children: [
          {
            index: true,
            element: <ListViewModules />,
          },
          {
            path: 'new',
            element: <AddModules />,
          },
          {
            path: 'edit/:id',
            element: <EditModules />,
          },
        ],
      },
      {
        path: 'roles',
        element: <RolesLayout />,
        children: [
          {
            index: true,
            element: <ListViewRoles />,
          },
          {
            path: 'new',
            element: <AddRoles />,
          },
          {
            path: 'edit/:roleID',
            element: <EditRoles />,
          },
        ],
      },
      {
        path: 'permissions',
        element: <PermissionsLayout />,
        children: [
          {
            index: true,
            element: <PermissionsListView />,
          },
          {
            path: 'new',
            element: <PermissionNew />,
          },
          {
            path: 'edit/:id',
            element: <PermissionEdit />,
          },
        ],
      },
      {
        path: 'configuration',
        element: <ConfigurationLayout />,
        children: [
          {
            index: true,
            element: <ConfigurationListView />,
          },
          {
            path: 'add',
            element: <ConfigurationAdd />,
          },
          {
            path: 'edit/:id',
            element: <ConfigurationEdit />,
          },
        ],
      },
      {
        path: 'status-flow',
        element: <FlowLayout />,
        children: [
          {
            index: true,
            element: <StatusFlowListView />,
          },
          {
            path: 'add',
            element: <StatusFlowAdd />,
          },
          {
            path: 'edit/:flowID',
            element: <StatusFlowEdit />,
          },
        ],
      },
      {
        path: 'itsm-flow',
        element: <FlowLayout />,
        children: [
          {
            index: true,
            element: <ItsmFlowListView />,
          },
          {
            path: 'add',
            element: <ItsmFlowAdd />,
          },
          {
            path: 'edit/:flowID',
            element: <ItsmFlowEdit />,
          },
        ],
      },
      {
        path: 'billing-accounts',
        element: <ListBillingAccounts />,
        // element: 0,
        // children: [
        //   {
        //     index: true,
        //     element: <ListBillingAccounts />,
        //   },
        // {
        //   path: 'new',
        //   element: <AddRoles />,
        // },
        // {
        //   path: 'edit/:roleID',
        //   element: <EditRoles />,
        // },
        // ],
      },
    ],
  },
  {
    path: 'erp/crm',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'organization',
        children: [
          {
            path: '',

            element: <ListViewOrganization />,
          },
          {
            path: 'new',
            element: <AddOrganization />,
          },
          {
            path: 'details/:organizationID',
            element: <OrganizationDetails />,
            children: [
              {
                path: 'project',
                element: <ProjectSection />,
              },
              {
                path: 'project/:projectID',
                element: <ProjectsSubTabs />,
                children: [
                  {
                    path: 'case',
                    element: <CasesViewByProjects />,
                  },
                  {
                    path: 'case/:caseID',
                    element: <EditCases isProjectCase/>,
                  },
                  {
                    path: 'user',
                    element: <ViewUsers />,
                  },
                  {
                    path: 'user/:userID',
                    element: <EditUser />,
                  },
                  {
                    path: 'meetings',
                    element: <MeetingsSection />,
                  },
                  {
                    path: 'notes',
                    element: <NoteSection />,
                  },
                  {
                    path: 'sprints',
                    element: <ViewSprints />,
                  },
                  {
                    path: 'release-version',
                    element: <ViewReleaseVersions />,
                  },
                ],
              },
              {
                path: 'contacts',
                children: [
                  {
                    path: '',
                    element: <ContactsSection />,
                  },
                  {
                    path: 'new',
                    element: <AddContacts orgEditComponent />,
                  },
                  {
                    path: 'edit/:id',
                    element: <EditContacts orgEditComponent />,
                  },
                ],
              },
              {
                path: 'events',
                element: <NoteSection />,
              },
              {
                path: 'consulting',
                element: <Consulting />,
              },
              {
                path: 'consulting/:consultingID',
                element: <CuDetails />,
                // element: <EditConsultingUnits />,
              },
              {
                path: 'client-settings',
                element: <ClientSettingsMain />,
              },
              // {
              //   path: 'white-label',
              //   element: <WhiteLabelSection />,
              // },
              {
                path: 'general-settings',
                element: <GeneralSettings />,
              },
            ],
          },
        ],
      },
      {
        path: 'employees',
        children: [
          {
            path: '',

            element: <ListViewEmployees />,
          },
          {
            path: 'new',
            element: <AddEmployees />,
          },
          {
            path: 'edit/:id',
            element: <EditEmployees />,
          },
        ],
      },
      {
        path: 'contacts',
        children: [
          {
            path: '',

            element: <ListViewContacts />,
          },
          {
            path: 'new',
            element: <AddContacts orgEditComponent={false} />,
          },
          {
            path: 'edit/:id',
            element: <EditContacts orgEditComponent={false} />,
          },
        ],
      },
    ],
  },
  // ---> other routes
  {
    path: '*',
    children: [
      {
        path: 'erp/login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'erp/forgot-password',
        element: <PasswordRecovery />,
      },
      {
        path: 'erp/reset-password',
        element: <PasswordReset />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: 'testing',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
