import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
} from '@mui/material';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { requestApi } from 'src/api/requestApi';
import FileUploadDragDrop from 'src/components/widgets/fileUpload/FileUploadDragDrop';
import { userApi } from 'src/api/userApi';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    createTeam: {
      width: '40%',
      padding: '5px 20px',
      zIndex: 1300,
      border: 'none',
      transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
      '& .ticketBody': {
        height: '90vh',
        overflowY: 'auto',
        padding: '0 20px',
        display: 'flex',
        flexDirection: 'column',
        '& input': {
          borderRadius: '2px',
        },
      },
      '& h4': {
        backgroundColor: '#C22027',
        color: '#fff',
        padding: '0 40px',
        margin: '-5px -20px 10px',
        height: '64px',
        display: 'flex',
        alignItems: 'center',
      },

      '& select, & .MuiAutocomplete-inputRoot, & input': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        '& .MuiAutocomplete-input': {
          padding: 0,
        },
      },
      '& .ticketDropDown': {
        marginTop: '15px',
      },
    },
  })
);

const validationSchema = Yup.object().shape({
  summary: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  categoryKey: Yup.object().nullable().required('Category is required'),
  user: Yup.object().nullable().required('Employee is required'),
  startDate: Yup.date().nullable().required('Start Date is required'),
  endDate: Yup.date()
    .nullable()
    .required('End Date is required')
    .min(Yup.ref('startDate'), 'End Date cannot be earlier than Start Date'),
});

const AddLeave = ({
  isOpen,
  addLeave,
  closeDrawer,
  fetchData,
}: {
  isOpen: boolean;
  closeDrawer: () => void;
  addLeave: (isFalse: boolean, e: any) => void;
  fetchData?: any;
}) => {
  const classes = useStyles();
  const [employees, setEmployees] = useState<any>([]);
  const [subCategory, setSubCategory] = useState<any>([]);
  const [filePath, setFilePath] = useState<Array<string>>([]);
  const categoryFocusRef = useRef(false);
  const employeeFocusRef = useRef(false);

  const fetchSubCategories = async () => {
    try {
      const response = await requestApi.getSubCategories('HR');
      setSubCategory(response?.data?.HR?.slice(2, 4));
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await userApi.getUsersByOrganizationId(
        null,
        null,
        25000,
        null
      );
      setEmployees(response?.data?.data);
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      summary: '',
      description: '',
      categoryKey: null,
      user: null,
      startDate: '',
      endDate: '',
      attachmentId: filePath,
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      const payload = {
        summary: values.summary,
        description: values.description,
        categoryKey: values.categoryKey.key,
        user:  values.user.id,          
        startDate: values.startDate,
        endDate: values.endDate,
        attachmentId: filePath,
      };
      try {
        await requestApi.addLeaveHandler(payload);
        toast.success(`Leave Added successfully!`);
        // Close the drawer after successful submission
        closeDrawer();
        // Fetch updated data
        fetchData();
        // Reset the form after successful submission
        actions.resetForm();
      } catch (error) {
        toast.error(`Error Adding Leave. Please try again.`);
        actions.setSubmitting(false); // Ensure the submit button becomes clickable again
      }
    },
  });

  const handleCancel = () => {
    // Reset the form and states on cancel button click
    formik.resetForm();
    setFilePath([]);
    closeDrawer();
  };

  useEffect(() => {
    fetchSubCategories();
    fetchEmployees();
  }, []);

  return (
    <div>
      <Drawer
        open={isOpen}
        anchor="right"
        onClose={(e) => addLeave(false, e)}
        style={{ zIndex: 1300 }}
        classes={{ paper: classes.createTeam }}>
        <Typography variant="h4" component="h4">
          Add Leave
        </Typography>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="ticketBody">
            <div className="ticketDropDown">
              <TextField
                id="one-textarea"
                fullWidth
                size="small"
                name="summary"
                placeholder="Summary"
                label="Summary"
                value={formik?.values?.summary}
                onChange={(e) => {
                  formik?.handleChange(e);
                }}
                error={Boolean(
                  formik?.touched?.summary && formik?.errors?.summary
                )}
                helperText={formik?.touched?.summary && formik?.errors?.summary}
              />
            </div>
            <div className="ticketDropDown">
              <TextField
                id="one-textarea"
                fullWidth
                size="small"
                multiline
                rows={4}
                name="description"
                placeholder="Description"
                label="Description"
                value={formik?.values?.description}
                onChange={(e) => {
                  formik?.handleChange(e);
                }}
                error={Boolean(
                  formik?.touched?.description && formik?.errors?.description
                )}
                helperText={
                  formik?.touched?.description && formik?.errors?.description
                }
              />
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                options={subCategory}
                getOptionLabel={(option) => option.name || 'No Category'}
                value={formik.values.categoryKey}
                onChange={(event, value) =>
                  formik.setFieldValue('categoryKey', value)
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Category"
                    label="Category"
                    InputLabelProps={{
                      sx: {
                        top: (() => {
                          if (formik.values.categoryKey) {
                            return '0px';
                          }
                          if (!categoryFocusRef.current) {
                            return '-7px';
                          }
                          return '0px';
                        })(),
                      },
                    }}
                    onFocus={() => {
                      categoryFocusRef.current = true;
                    }}
                    onBlur={() => {
                      categoryFocusRef.current = false;
                    }}
                    error={
                      formik.touched.categoryKey &&
                      Boolean(formik.errors.categoryKey)
                    }
                    helperText={
                      formik.touched.categoryKey && formik.errors.categoryKey
                    }
                  />
                )}
              />
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                options={employees}
                getOptionLabel={(option) => option.name || 'No Employee'}
                value={formik.values.user}
                onChange={(event, value) => formik.setFieldValue('user', value)}
                isOptionEqualToValue={(option, value) =>
                  option.id === value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Employee"
                    label="Employee"
                    InputLabelProps={{
                      sx: {
                        top: (() => {
                          if (formik.values.user) {
                            return '0px';
                          }
                          if (!employeeFocusRef.current) {
                            return '-7px';
                          }
                          return '0px';
                        })(),
                      },
                    }}
                    onFocus={() => {
                      employeeFocusRef.current = true;
                    }}
                    onBlur={() => {
                      employeeFocusRef.current = false;
                    }}
                    error={formik.touched.user && Boolean(formik.errors.user)}
                    helperText={formik.touched.user && formik.errors.user}
                  />
                )}
              />
            </div>
            <div className="ticketDropDown">
              <TextField
                id="startDate"
                fullWidth
                size="small"
                type="date"
                name="startDate"
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                value={formik.values.startDate}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.startDate && formik.errors.startDate
                )}
                helperText={formik.touched.startDate && formik.errors.startDate}
              />
            </div>
            <div className="ticketDropDown">
              <TextField
                id="endDate"
                fullWidth
                size="small"
                type="date"
                name="endDate"
                label="End Date"
                InputLabelProps={{ shrink: true }}
                value={formik.values.endDate}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.endDate && formik.errors.endDate)}
                helperText={formik.touched.endDate && formik.errors.endDate}
              />
            </div>
            <div className="ticketDropDown">
              <FileUploadDragDrop
                setFilePath={setFilePath}
                filePath={filePath}
              />
            </div>
            <Box display="flex" alignSelf="flex-end" mt={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCancel}
                sx={{ marginLeft: 'auto', height: '38px' }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                sx={{ height: '38px', ml: 1 }}
                disabled={formik.isSubmitting}>
                Add
              </Button>
            </Box>
          </div>
        </form>
      </Drawer>
    </div>
  );
};

export default AddLeave;
