import { useState, useEffect } from 'react';
import { Avatar, Box, Button, TextField, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useParams } from 'react-router';
import { requestApi } from 'src/api/requestApi';
import toast from 'react-hot-toast';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      marginTop: '12px',
      '& .userInfo': {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-root': {
          marginLeft: '10px',
          fontSize: '12px',
        },
      },
      '& .requestReason': {
        marginTop: '12px',
        '& h4': {
          color: '#423636',
          fontSize: '14px',
        },
        '& .MuiTypography-root': {
          fontSize: '12px',
        },
      },
    },
    avatar: {
      width: '30px',
      height: '30px',
    },
    descriptionText: {
      cursor: 'pointer',
      fontSize: '12px',
      color: '#000',
    },
    textField: {
      width: '100%',
      marginTop: '8px',
    },
    button: {
      marginTop: '12px',
      fontSize: '12px',
      marginRight: '8px', // Add some spacing between buttons
    },
  })
);

type RequestInfoTypes = {
  creater: string;
  date: string;
  answer: string;
};

export const RequestInfo = ({ creater, date, answer }: RequestInfoTypes) => {
  const classes = useStyles();
  const { requestID } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(answer);
  const [originalDescription, setOriginalDescription] = useState(answer);

  // Sync description with answer prop when the prop changes
  useEffect(() => {
    setDescription(answer);
    setOriginalDescription(answer); // Keep track of original description
  }, [answer]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setDescription(originalDescription); // Revert changes
    setIsEditing(false); // Close editing mode
  };

  const handleUpdateClick = async () => {
    try {
      await requestApi.updateHrDescription(requestID, { description });
      toast.success('Description updated successfully');
      setIsEditing(false);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  return (
    <div className={classes.root}>
      <Box className="userInfo">
        <Avatar src="assets/images/avatar.png" className={classes.avatar} />
        <Typography>
          <span style={{ fontWeight: 'bold' }}>{creater}</span> raised this on{' '}
          {moment(date).format('LL')}
        </Typography>
      </Box>
      <Box className="requestReason">
        {!isEditing ? (
          <Typography
            className={classes.descriptionText}
            onClick={handleEditClick}
          >
            {description || 'Click to add a description'}
          </Typography>
        ) : (
          <>
            <TextField
              className={classes.textField}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={4}
              variant="outlined"
            />
            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
              onClick={handleUpdateClick}
            >
              Update
            </Button>
            <Button
              className={classes.button}
              color="secondary"
              variant="outlined"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
          </>
        )}
      </Box>
    </div>
  );
};
