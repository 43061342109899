import { Box, Container, Grid } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { employeesApi } from 'src/api/employeesApi';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import Form from 'src/components/widgets/forms/Form';
import logger from 'src/utils/logger';
import setTitle from 'src/utils/setTitle';
import * as Yup from 'yup';
import useSettings from '../../../hooks/useSettings';
import { rolesApi } from 'src/api/rolesApi';
import useAuth from 'src/hooks/useAuth';
import { REGEX } from 'src/utils/constants';
import { RoleTypes } from 'src/types/globalTypes';
import { leavesApi } from 'src/api/leavesApi';
import { Tab, Tabs } from '@mui/material';
import moment from 'moment';
import {
  differenceInYears,
  differenceInMonths,
  differenceInDays,
} from 'date-fns';
import { teamsApi } from 'src/api/teams';
import { isNaN } from 'lodash';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const calculateDuration = (startDate: string) => {
  if (!startDate) return '';

  const today = new Date();
  const start = new Date(startDate);

  if (isNaN(start.getTime())) {
    console.error('Invalid date format:', startDate);
    return '';
  }

  let years = today.getFullYear() - start.getFullYear();
  let months = today.getMonth() - start.getMonth();
  let days = today.getDate() - start.getDate();

  // Adjust for negative days
  if (days < 0) {
    months--;
    days += new Date(today.getFullYear(), today.getMonth(), 0).getDate(); // Get days in previous month
  }

  // Adjust for negative months
  if (months < 0) {
    years--;
    months += 12;
  }

  let duration = '';

  if (years >= 1) {
    duration += `${years} year${years > 1 ? 's' : ''}`;
  }

  if (months >= 1) {
    if (duration) duration += ', ';
    duration += `${months} month${months > 1 ? 's' : ''}`;
  }

  if (days >= 1) {
    if (duration) duration += ', ';
    duration += `${days} day${days > 1 ? 's' : ''}`;
  }

  return duration || '0 days';
};

const calculateAge = (dateOfBirth) => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  // If birth month hasn't passed yet or it's the birth month but day hasn't passed, subtract one year
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

const EditEmployees: FC = () => {
  setTitle('CRM | Employees - Edit');
  const { user } = useAuth();
  const { settings } = useSettings();
  const returnUrl = '/erp/crm/employees';
  const { id } = useParams();
  const navigate = useNavigate();
  type Role = {
    id?: number;
  };
  type EmployeeData = {
    firstName: string;
    lastName: string;
    designation: string;
    roles?: RoleTypes[];
    email: string;
    contactNumber: string;
    avatar: string;
    employeeId?: string;
    employeeType?: string;
    joiningDate: any;
    stayedWithUs: string;
    info: string;
    oldPassword?: string;
    newPassword?: string;
    confirmNewPassword?: string;
    roleId?: Role[];
    allocatedLeaves?: {
      paid: number;
      medical: number;
      unpaid: number;
      holidays: number;
    };
    paid: number;
    medical: number;
    unpaid: number;
    holidays: number;
    id?: string;
    createdAt?: any;
    gender?: string;
    maritalStatus?: string;
    bloodGroup: string;
    dateOfBirth: string;
    age: any;
    address?: string;
    teams?: Array<Object>;
  };

  const [roles, setRoles] = useState<RoleTypes[]>([]);
  const [employeeData, setEmployeeData] = useState<EmployeeData>(null);
  const [teams, setTeams] = useState([]);
  const [tabsValue, setTabsValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };
  console.log('employeeData', employeeData);

  const InitialValues: EmployeeData = {
    firstName: employeeData?.firstName || '',
    lastName: employeeData?.lastName || '',
    designation: employeeData?.designation || '',
    roles: employeeData?.roles || [],
    email: employeeData?.email || '',
    contactNumber: employeeData?.contactNumber
      ? employeeData?.contactNumber.toString()
      : '',
    avatar: employeeData?.avatar || '',
    employeeId: employeeData?.employeeId || '',
    employeeType: employeeData?.employeeType || '',
    joiningDate: employeeData?.joiningDate,
    stayedWithUs: calculateDuration(employeeData?.joiningDate) || '',
    gender: employeeData?.gender || '',
    maritalStatus: employeeData?.maritalStatus || '',
    bloodGroup: employeeData?.bloodGroup || '',
    dateOfBirth: employeeData?.dateOfBirth || '',
    age: calculateAge(employeeData?.dateOfBirth || '') || '',
    info: employeeData?.info || '',
    address: employeeData?.address || '',
    paid: employeeData?.allocatedLeaves?.paid || 0,
    medical: employeeData?.allocatedLeaves?.medical || 0,
    unpaid: employeeData?.allocatedLeaves?.unpaid || 0,
    holidays: employeeData?.allocatedLeaves?.holidays || 0,
    teams: employeeData?.teams || [],
  };
  const SecurityInitialValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const previewData = [
    {
      name: 'employee details',
      fields: [
        {
          id: '1',
          name: 'firstName',
          label: 'First Name',
          placeholder: 'First Name',
          type: 'text',
          required: true,
          fullWidth: true,
        },
        {
          id: '2',
          name: 'lastName',
          label: 'Last Name',
          placeholder: 'Last Name',
          type: 'text',
          required: true,
          fullWidth: true,
        },
        {
          id: '4',
          name: 'designation',
          label: 'Designation',
          placeholder: 'Designation',
          type: 'text',
          required: true,
          fullWidth: true,
        },
        {
          id: '5',
          name: 'roles',
          label: 'Role',
          placeholder: 'Role',
          type: 'muiAutoComplete',
          required: true,
          columnSize: 6,
          array: roles?.filter(
            (item) => !item.identifier.includes('client_contact')
          ),
        },
        {
          id: '6',
          name: 'email',
          label: 'Email',
          placeholder: 'Email',
          type: 'email',
          required: true,
          fullWidth: true,
        },
        {
          id: '7',
          name: 'contactNumber',
          label: 'Phone Number',
          placeholder: 'Phone Number',
          type: 'text',
          required: true,
          fullWidth: true,
        },
        {
          id: '9',
          name: 'employeeId',
          label: 'Employee Id',
          placeholder: 'Employee Id',
          type: 'text',
          required: true,
          fullWidth: true,
          // InputProps: {
          //   readOnly: true,
          // },
        },
        {
          id: '10',
          name: 'employeeType',
          label: 'Employee Type',
          placeholder: 'Employee Id',
          type: 'dropDownSimple',
          required: true,
          fullWidth: true,
          columnSize: 6,
          array: [
            { id: 'permanent', name: 'Permanent' },
            { id: 'onContract', name: 'On-Contract' },
          ],
        },
        {
          id: '11',
          name: 'joiningDate',
          label: 'Joining Date',
          placeholder: 'Joining Date',
          type: 'date',
          required: true,
          fullWidth: true,
          columnSize: 6,
          InputLabelProps: {
            shrink: true, // This ensures the label stays above the field
          },
          // InputProps: {
          //   readOnly: false,
          // },
        },
        {
          id: '12',
          name: 'stayedWithUs',
          label: 'Stay With Us',
          placeholder: 'Stay With Us',
          type: 'text',
          fullWidth: true,
          columnSize: 6,
          InputProps: {
            readOnly: true,
          },
        },
        {
          id: '15',
          name: 'teams',
          label: 'Teams',
          type: 'autocomplete', // use 'autocomplete' instead of 'muiAutoComplete'
          multiple: true, // Allow multiple team selections
          options:
            teams?.map((team) => ({
              teamId: team.id,
              teamName: team.name,
            })) || [], // Pass the list of team options
          getOptionLabel: (option) => option.teamName, // Display team name in the dropdown
          isOptionEqualToValue: (option, value) =>
            option.teamId === value.teamId,
          columnSize: 6, // Adjust column size as needed
        },
        {
          id: '81',
          name: 'avatar',
          label: 'Image',
          placeholder: 'Image',
          type: 'file',
          required: true,
          fullWidth: true,
        },
      ],
    },
    {
      name: 'Personal Information',
      fields: [
        {
          id: '21',
          name: 'gender',
          label: 'Gender',
          placeholder: 'Gender',
          type: 'dropDownSimple',
          fullWidth: true,
          columnSize: 6,
          array: [
            { id: 'male', name: 'Male' },
            { id: 'female', name: 'Female' },
          ],
        },
        {
          id: '22',
          name: 'maritalStatus',
          label: 'Marital Status',
          placeholder: 'Marital Status',
          type: 'dropDownSimple',
          fullWidth: true,
          columnSize: 6,
          array: [
            { id: 'single', name: 'Single' },
            { id: 'married', name: 'Married' },
          ],
        },
        {
          id: '23',
          name: 'bloodGroup',
          label: 'Blood Group',
          placeholder: 'Blood Group',
          type: 'text',
          fullWidth: true,
        },
        {
          id: '23',
          name: 'dateOfBirth',
          label: 'Date of Birth',
          placeholder: 'Date of Birth',
          type: 'date',
          required: true,
          fullWidth: true,
          InputLabelProps: {
            shrink: true, // This ensures the label stays above the field
          },
          // InputProps: {
          //   readOnly: true,
          // },
        },
        {
          id: '24',
          name: 'age',
          label: 'Age',
          placeholder: 'Age',
          type: 'text',
          required: true,
          disabled: true,
          fullWidth: true,
          InputLabelProps: {
            shrink: true, // This ensures the label stays above the field
          },
        },
      ],
    },
    {
      name: 'Address',
      fields: [
        {
          id: '11',
          name: 'info',
          label: 'Address',
          placeholder: 'Address',
          type: 'textarea',
          fullWidth: true,
        },
      ],
    },
    {
      name: 'Leaves Quota',
      fields: [
        {
          id: '12',
          name: 'paid',
          label: 'Paid Leaves (days)',
          placeholder: 'Enter Paid Leaves in Days',
          type: 'number',
          required: true,
          fullWidth: true,
          InputLabelProps: { shrink: true }, // Ensure the label shrinks above the field
        },
        {
          id: '13',
          name: 'medical',
          label: 'Medical Leaves (days)',
          placeholder: 'Enter Medical Leaves in Days',
          type: 'number',
          required: true,
          fullWidth: true,
          InputLabelProps: { shrink: true }, // Ensure the label shrinks above the field
        },
        {
          id: '14',
          name: 'unpaid',
          label: 'Unpaid Leaves (days)',
          placeholder: 'Enter Unpaid Leaves in Days',
          type: 'number',
          required: true,
          fullWidth: true,
          InputLabelProps: { shrink: true }, // Ensure the label shrinks above the field
        },
        {
          id: '15',
          name: 'holidays',
          label: 'Holidays (days)',
          placeholder: 'Enter Holidays in Days',
          type: 'number',
          required: true,
          fullWidth: true,
          InputLabelProps: { shrink: true }, // Ensure the label shrinks above the field
        },
      ],
    },
  ];
  const securityData = [
    {
      name: 'Security',
      fields: [
        {
          id: '8',
          name: 'oldPassword',
          label: 'Old Password',
          placeholder: 'Old Password',
          type: 'password',
          required: true,
          fullWidth: true,
        },
        {
          id: '9',
          name: 'newPassword',
          label: 'New Password',
          placeholder: 'New Password',
          type: 'password',
          required: true,
          fullWidth: true,
        },
        {
          id: '10',
          name: 'confirmNewPassword',
          label: 'Confirm New Password',
          placeholder: 'Confirm New Password',
          type: 'password',
          required: true,
          fullWidth: true,
        },
      ],
    },
  ];

  const Schema = Yup.object({
    firstName: Yup.string()
      .required('First name required')
      .min(3, 'Minimum 3 characters allowed')
      .max(50, 'Maximum 50 characters allowed')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    lastName: Yup.string()
      .required('Last name required')
      .min(3, 'Minimum 3 characters allowed')
      .max(50, 'Maximum 50 characters allowed')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    email: Yup.string().email().required('Email required'),
    contactNumber: Yup.string()
      .required('Phone number required')
      .matches(
        /^.{10,20}$/,
        'Phone number must be between 10 and 20 characters in length'
      ),
    designation: Yup.string()
      .required('Designation required')
      .min(3, 'Minimum 3 characters required')
      .max(100, 'Maximum 100 characters allowed')
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    roles: Yup.array()
      .nullable()
      .test(
        'is-nonempty',
        'Role required',
        (value) => value && value?.length > 0
      ),
    info: Yup.string().max(2000, 'Maximum 2000 characters allowed'),
    paid: Yup.number().required('Paid leaves required').min(0),
    medical: Yup.number().required('Medical leaves required').min(0),
    unpaid: Yup.number().required('Unpaid leaves required').min(0),
    holidays: Yup.number().required('Holidays required').min(0),
  });

  const securitySchema = Yup.object({
    oldPassword: Yup.string()
      .max(255, 'Maximum 255 characters allowed')
      .trim()
      .required('Old Password is required'),
    newPassword: Yup.string()
      .trim()
      .min(8, 'Minium 8 characters required')
      .max(20, 'Maximum 20 characters allowed')
      .matches(
        REGEX.password,
        'At least one uppercase, one lowercase, one number & one special character allowed'
      )
      .required('New Password is required'),
    confirmNewPassword: Yup.string()
      .trim()
      .test(
        'Password match',
        'Password does not match',
        (value, context) => value === context.parent.newPassword
      )
      .required('Confirm New Password is required'),
  });
  const handleOnSubmit = async (values: EmployeeData, actions) => {
    try {
      const payload = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        designation: values?.designation,
        roleId: values?.roles?.map((role: RoleTypes) => role?.id),
        email: values?.email,
        contactNumber: values?.contactNumber,
        avatar: values?.avatar,
        info: values?.info,
        oldPassword: values?.oldPassword,
        password: values?.newPassword,
        employeeType: values?.employeeType,
        employeeId: values?.employeeId,
        joiningDate: values?.joiningDate,
        gender: values?.gender,
        maritalStatus: values?.maritalStatus,
        bloodGroup: values?.bloodGroup,
        dateOfBirth: values?.dateOfBirth,
        address: values?.address,
      };

      // Send allocatedLeaves data to another API
      const leavesPayload = {
        paid: values?.paid * 8, // Convert days to hours
        medical: values?.medical * 8, // Convert days to hours
        unpaid: values?.unpaid * 8, // Convert days to hours
        holidays: values?.holidays * 8, // Convert days to hours
      };

      const teamsPayload = {
        teams: values?.teams,
      };

      if (!values?.oldPassword || !values?.newPassword) {
        delete payload.oldPassword;
        delete payload.password;
      }

      const teamsResponse = await teamsApi.updateEmployeeTeams(
        employeeData?.id,
        teamsPayload
      );
      const leavesResponse = await leavesApi.updateEmployeeLeaveQuota(
        `${id}`,
        leavesPayload
      );
      const response = await employeesApi.updateById(`${id}`, payload);
      if (
        (teamsResponse?.status === 200 || teamsResponse?.status === 201) &&
        (leavesResponse?.status === 200 || leavesResponse?.status === 201) &&
        (response?.status === 200 || response?.status === 201)
      ) {
        toast.success('Updated Successfully');
        navigate(returnUrl);
      } else {
        throw new Error('Failed to update some data');
      }
    } catch (error) {
      if (error?.response?.status === 500) {
        toast.error('Internal Server Error');
      } else if (error?.response?.data?.message) {
        toast.error(`${error.response.data.message}`);
      } else {
        toast.error('An error occurred during the update');
      }
      actions.setSubmitting(false);
    }
  };

  const BreadCrumbsData = {
    title: 'Edit Employee',
    links: [
      {
        name: 'Employees',
        to: returnUrl,
      },
      {
        name: 'Edit Employee',
        to: `${returnUrl}/edit/${id}`,
      },
    ],
  };

  const getEmployeeTeamsData = async (employee) => {
    try {
      const response = await teamsApi.getEmployeeTeams(employee.id);
      if (response?.status === 200) {
        setEmployeeData({
          ...employee,
          teams:
            response.data.map((team) => ({
              teamId: team.id,
              teamName: team.name,
            })) || [],
        });
      } else {
        setEmployeeData({
          ...employee,
        });
      }
    } catch (error) {
      setEmployeeData({
        ...employee,
      });
    }
  };

  const getEmployeeData = async () => {
    try {
      const response = await employeesApi.getById(`${id}`);
      if (response?.status === 200) {
        let fetchedData = response?.data;

        console.log(fetchedData);

        fetchedData = {
          ...fetchedData,
          allocatedLeaves: {
            paid: fetchedData?.leaves?.allocatedLeaves?.paid / 8 || 0, // Convert hours to days
            medical: fetchedData?.leaves?.allocatedLeaves?.medical / 8 || 0, // Convert hours to days
            unpaid: fetchedData?.leaves?.allocatedLeaves?.unpaid / 8 || 0, // Convert hours to days
            holidays: fetchedData?.leaves?.allocatedLeaves?.holidays / 8 || 0, // Convert hours to days
          },
        };
        setEmployeeData({
          ...fetchedData,
        });
        getEmployeeTeamsData(fetchedData);
      } else {
        setEmployeeData(null);
      }
    } catch (error) {
      logger(error, 'error');
      setEmployeeData(null);
    }
  };

  const getAllRoles = async () => {
    try {
      const response = await rolesApi.getAllActiveRoles();
      if (response?.status === 200) {
        setRoles(response?.data);
      } else {
        setRoles([]);
      }
    } catch (error) {
      setRoles([]);
    }
  };

  const getTeams = async () => {
    try {
      const response = await teamsApi.getTeamsAutocomplete(); // Replace with actual API call
      if (response?.status === 200) {
        setTeams(response.data); // Update state with team data
      } else {
        setTeams([]);
      }
    } catch (error) {
      setTeams([]);
    }
  };

  useEffect(() => {
    if (
      user?.permissions?.includes('employee-get-by-id') ||
      user?.permissions?.includes('employee-update')
    ) {
      getEmployeeData();
      getAllRoles();
      getTeams();
    } else {
      window.location.href = '/admin/not-found';
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <SimpleBreadCrumb data={BreadCrumbsData} />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabsValue}
                onChange={handleChange}
                aria-label="basic tabs example">
                <Tab label="Employee Details" {...a11yProps(0)} />
                <Tab label="Security" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabsValue} index={0}>
              <Form
                previewer={previewData}
                schema={Schema}
                handleOnSubmit={handleOnSubmit}
                initialValues={InitialValues}
                returnUrl={returnUrl}
                showButtons={user?.permissions?.includes('employee-update')}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabsValue} index={1}>
              <Form
                previewer={securityData}
                schema={securitySchema}
                handleOnSubmit={handleOnSubmit}
                initialValues={SecurityInitialValues}
                returnUrl={returnUrl}
                showButtons={user?.permissions?.includes('employee-update')}
              />
            </CustomTabPanel>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default EditEmployees;
