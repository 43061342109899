import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
} from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import ArrowIcon from './ArrowIcon';
import { casesApi } from 'src/api/casesApi';
import { billingApi } from 'src/api/billingApi';
import toast from 'react-hot-toast';
import { projectApi } from 'src/api/projectApi';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    margin: '30px',
    '& .MuiFormControlLabel-label': {
      fontSize: '15px !important',
    },
    '& .moveBtn': {
      display: 'flex',
      justifyContent: 'end',
    },
    '& .listAccounts': {
      display: 'flex',
      alignItems: 'center',
      // marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
      justifyContent: 'center',
      // gap: '10px'
    },
    '& .outerlistAccounts': {
      display: 'flex',
      alignItems: 'center',

      justifyContent: 'center',
    },
    '& .textFieldName input': {
      padding: '9px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '4px',
      paddingLeft: '10px',
      fontSize: '16px',
      color: '#000',
      // fontWeight: 600,
      // color: '#000 !important'
    },
    '& .Mui-disabled': {
      opacity: 1,
      // '-webkit-text-fill-color': '#000'
    },
  },
  boxHeight: {
    height: '90px',
  },
  formControl: {
    width: '100%',
  },
  button: {
    backgroundColor: '#c22027',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#d43f40',
    },
    alignSelf: 'flex-end',
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formLabel: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontWeight: 600,
    fontSize: 14,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  subtitle: {
    marginLeft: theme.spacing(3),
  },
  controlLabel: {
    marginLeft: theme.spacing(3),
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    justifyContent: 'center',
  },

  arrow: {
    width: '35px',
    marginRight: theme.spacing(2),
    marginTop: '20px',
    marginLeft: theme.spacing(2),
  },
  arrowAcc: {
    width: '35px',
    marginRight: theme.spacing(2),
    marginTop: '20px',
    marginLeft: theme.spacing(2),
  },
  box: {
    padding: theme.spacing(1.35),
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    height: '40px',
  },
  selectFormControl: {
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
  },
  selectFormControlAcc: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectFormControlAccount: {
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
  },
  formInputLabel: {
    fontSize: '10px',
    fontWeight: '600',
    color: '#000 !important',
    marginBottom: '8px',
  },
  selectInput: {
    height: '40px',
  },
  accForm: {
    input: {
      padding: '6px !important',
    },
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
}));

const MoveCases: FC<any> = ({ projectName, selectedIds, moveCases }) => {
  const { projectID }: any = useParams();
  const classes = useStyles();
  const navigate = useNavigate();

  const [projects, setProjects] = useState([]);
  const [projectsById, setProjectsById] = useState([]);
  const [allAccountsById, setAllAccountsById] = useState([]);
  const [page, setPage] = useState<number>(
    JSON.parse(localStorage.getItem('billingAccountsPage'))?.casesPageNo || 0
  );
  const [pageSize, setPageSize] = useState<number>(
    JSON.parse(localStorage.getItem('billingAccountsPage'))?.casesPageLimit ||
      25
  );
  const [search, setSearch] = useState<string>(
    JSON.parse(localStorage.getItem('billingAccountsPage'))?.casesSearch || ''
  );
  const [oldProjectDetail, setOldProjectDetail] = useState<string>('');
  const [newProjectDetail, setNewProjectDetail] = useState<string>('');

  const fetchAllProjects = async () => {
    try {
      const res = await casesApi.getAllProjects();
      const filteredData = res.data.filter((item) => item.id !== projectID);
      setProjects(filteredData);
    } catch (error) {
      toast.error('Failed to load projects');
    }
  };

  const getAccountByProj = async (projId) => {
    try {
      const res = await billingApi.getAllBillingAccountsByProject(projId);
      setAllAccountsById(res.data.data);
    } catch (error) {
      toast.error('Failed to load billing accounts');
    }
  };

  const getPreSelectAccount = async () => {
    try {
      const res = await billingApi.getAllBillingAccountsByProject(projectID);
      setProjectsById(res.data.data);
    } catch (error) {
      toast.error('Failed to load billing accounts');
    }
  };

  // getPreSelectAccount
  // const getAllBillingAccounts = async () => {
  //   try {
  //     const payload = { page, pageSize, search };
  //     const keys = Object.keys(payload);
  //     const values = Object.values(payload);

  //     const queryParams = [];
  //     for (let i = 0; i < values.length; i++) {
  //       if (values[i] !== '' && values[i] !== null && values[i] !== undefined) {
  //         queryParams.push(`${encodeURIComponent(keys[i])}=${values[i]}`);
  //       }
  //     }
  //     const queryString = queryParams.join('&');
  //     const response = await billingApi.getAllBillingAccounts(queryString);

  //     if (response?.status === 200) {
  //       setAllAccountsById(response?.data?.data);
  //     } else {
  //       setAllAccountsById([]);
  //     }
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message);
  //     setAllAccountsById([]);
  //   }
  // };
  const fetchNewProjectById = async (projId) => {
    const res = await projectApi.getProjectDetailsById(projId);
    setNewProjectDetail(res?.data);
  };
  const fetchOldProjectById = async (projId) => {
    const res = await projectApi.getProjectDetailsById(projId);
    setOldProjectDetail(res?.data);
  };

  const send = (values: any) => {
    if (!values.moveTo) {
      toast.error('Please select a project.');
      return;
    }

    const accountMoveDetails = Object.entries(values.accounts).map(
      ([accountId, moveToAccount]) => {
        const accountMoveFrom = projectsById.find(
          (account) => account.id === accountId
        );

        return {
          accountMoveFrom: {
            name: accountMoveFrom?.name,
            id: accountMoveFrom?.id,
          },
          accountMoveTo: {
            name: (moveToAccount as any).name,
            id: (moveToAccount as any).id,
          },
        };
      }
    );

    const payload = {
      project: values.moveTo,
      account: accountMoveDetails,
      new: newProjectDetail,
      old: oldProjectDetail,
    };

    moveCases(payload);
  };

  useEffect(() => {
    fetchAllProjects();
    // getAllBillingAccountsByProject();
    getPreSelectAccount();
    fetchOldProjectById(projectID);
  }, []);

  const validationSchema = Yup.object({
    moveTo: Yup.mixed().required('Please select a project to move to'),
    accounts: Yup.object().shape(
      projectsById.reduce((acc, account) => {
        acc[account.id] = Yup.mixed().required(`Value Required`);
        return acc;
      }, {})
    ),
  });

  const selectProject = (selectedValue: any) => {
    getAccountByProj(selectedValue.id);
    fetchNewProjectById(selectedValue.id);
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{ moveTo: '', accounts: {} }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          send(values);
          setSubmitting(false);
        }}>
        {({ handleChange, setFieldValue, getFieldProps }) => {
          console.log(getFieldProps('moveTo'));
          return (
            <Form>
              <Typography variant="h6" className={classes.formLabel}>
                Step 3 of 4: Select destination projects to move cases
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.subtitle}>
                Choose which action you&apos;d like to take on the selected
                issues.
              </Typography>
              <Divider className={classes.divider} />
              <Box className={classes.selectContainer}>
                <Box
                  className={`${classes.selectFormControl} ${classes.boxHeight}`}>
                  <FormLabel className={classes.formInputLabel}>
                    Project Move From
                  </FormLabel>
                  {/* <Box className={classes.box}>
                  <Typography variant="h6">{projectName}</Typography>
                </Box> */}
                  <TextField
                    className="textFieldName"
                    fullWidth
                    id="fullWidth"
                    defaultValue={projectName}
                    disabled
                  />
                </Box>

                <ArrowIcon className={classes.arrow} />
                <Box className={`${classes.boxHeight}`}>
                  <FormControl className={classes.selectFormControl}>
                    <FormLabel className={classes.formInputLabel}>
                      Project Move To
                    </FormLabel>
                    <Field
                      name="moveTo"
                      as={Select}
                      className={classes.selectInput}
                      // onChange={handleChange}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setFieldValue('moveTo', selectedValue);
                        selectProject(selectedValue); // Call your custom function
                      }}>
                      {projects?.map((project) => (
                        <MenuItem key={project.id} value={project}>
                          {project.name}
                        </MenuItem>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="moveTo"
                      component="div"
                      className={classes.error}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Divider className={classes.divider} />

              {allAccountsById.length > 0 ? (
                <Box className="outerlistAccounts">
                  <Box className={classes.selectFormControlAcc}>
                    {projectsById?.map((account) => (
                      <Box className="listAccounts" key={account.id}>
                        <Box
                          className={`${classes.accForm} ${classes.boxHeight}`}
                          sx={{ width: '250px' }}>
                          <FormLabel className={classes.formInputLabel}>
                            Account Move From
                          </FormLabel>
                          <TextField
                            className="textFieldName"
                            fullWidth
                            id="fullWidth"
                            defaultValue={account.name}
                            disabled
                          />
                        </Box>
                        <ArrowIcon className={classes.arrowAcc} />
                        <Box
                          className={`${classes.boxHeight}`}
                          sx={{ position: 'relative', top: '7px' }}>
                          <FormControl
                            className={classes.selectFormControlAccount}>
                            <FormLabel className={classes.formInputLabel}>
                              Account Move To
                            </FormLabel>
                            <Field
                              name={`accounts.${account.id}`}
                              as={Select}
                              className={classes.selectInput}
                              onChange={(e) =>
                                setFieldValue(
                                  `accounts.${account.id}`,
                                  e.target.value
                                )
                              }>
                              {allAccountsById?.map((Acc) => (
                                <MenuItem key={Acc.id} value={Acc}>
                                  {Acc.name}
                                </MenuItem>
                              ))}
                            </Field>
                            <ErrorMessage
                              name={`accounts.${account.id}`}
                              component="div"
                              className={classes.error}
                            />
                          </FormControl>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : (
                getFieldProps('moveTo').value !== '' && (
                  <Box className={classes.selectContainer}>
                    Please Add a Billing account first
                  </Box>
                )
              )}
              <Box className="moveBtn">
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.button}
                  disabled={allAccountsById.length <= 0}>
                  Next
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default MoveCases;
