import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
} from '@mui/material';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { userApi } from 'src/api/userApi';
import { teamsApi } from 'src/api/teams';
import toast from 'react-hot-toast';
import { isEmpty } from 'lodash';
import TeamsTable from './teams-table';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    createTeam: {
      width: '40%',
      padding: '5px 20px',
      zIndex: 1300,
      border: 'none',
      transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
      '& .ticketBody': {
        height: '90vh',
        overflowY: 'auto',
        padding: '0 20px',
        display: 'flex',
        flexDirection: 'column',
        '& input': {
          borderRadius: '2px',
        },
      },
      '& h4': {
        backgroundColor: '#C22027',
        color: '#fff',
        padding: '0 40px',
        margin: '-5px -20px 10px',
        height: '64px',
        display: 'flex',
        alignItems: 'center',
      },

      '& select, & .MuiAutocomplete-inputRoot, & input': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        '& .MuiAutocomplete-input': {
          padding: 0,
        },
      },
      '& .ticketDropDown': {
        marginTop: '15px',
      },
    },
  })
);

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  feManager: Yup.object().nullable().required('FE Manager is required'),
  feTeamLead: Yup.object().nullable().required('FE Team Lead is required'),
  beManager: Yup.object().nullable().required('BE Manager is required'),
  beTeamLead: Yup.object().nullable().required('BE Team Lead is required'),
  projectManager: Yup.object()
    .nullable()
    .required('Project Manager is required'),
});

const AddTeam = ({
  isOpen,
  addTeam,
  closeDrawer,
  fetchData,
  specificRowData,
}: {
  isOpen: boolean;
  closeDrawer: () => void;
  addTeam: (isFalse: boolean, e: any) => void;
  fetchData: any;
  specificRowData: any;
}) => {
  const classes = useStyles();
  const [employees, setEmployees] = useState<any>([]);

  console.log(specificRowData);

  const memberColumns = [
    {
      id: 'name',
      label: 'Member Name',
      width: '55%',
      render: (row) => (
        <Typography variant="body2">{row.name || '-'}</Typography>
      ),
    },
    {
      id: 'role',
      label: 'Role',
      width: '45%',
      render: (row) => (
        <Typography variant="body2">{row.role || '-'}</Typography>
      ),
    },
  ];

  const fetchEmployees = async () => {
    try {
      const response = await userApi.getUsersByOrganizationId(
        null,
        null,
        25000,
        null
      );
      setEmployees(response?.data?.data);
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: specificRowData?.name || '',
      feManager: specificRowData?.feManager || null,
      feTeamLead: specificRowData?.feTeamLead || null,
      beManager: specificRowData?.beManager || null,
      beTeamLead: specificRowData?.beTeamLead || null,
      projectManager: specificRowData?.projectManager || null,
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      const extractIdAndName = (person) =>
        person ? { id: person.id, name: person.name } : null;

      const payload = {
        name: values.name, // Full name for the team
        feManager: extractIdAndName(values.feManager),
        feTeamLead: extractIdAndName(values.feTeamLead),
        beManager: extractIdAndName(values.beManager),
        beTeamLead: extractIdAndName(values.beTeamLead),
        projectManager: extractIdAndName(values.projectManager),
      };

      try {
        if (!isEmpty(specificRowData)) {
          await teamsApi.editTeam({ id: specificRowData?._id, payload });
        } else {
          await teamsApi.addTeams(payload);
        }

        toast.success(
          `Team ${
            !isEmpty(specificRowData) ? 'Updated' : 'Created'
          } successfully!`
        );
        // Close the drawer after successful submission
        closeDrawer();
        // validate the all teams api
        fetchData();
        // Reset the form after successful submission
        actions.resetForm();
      } catch (error) {
        toast.error(
          `Error ${
            !isEmpty(specificRowData) ? 'Updating' : 'Creating'
          } team. Please try again.`
        );
        actions.setSubmitting(false); // Ensure the submit button becomes clickable again
      }
    },
  });

  useEffect(() => {
    fetchEmployees();
  }, []);

  return (
    <div>
      <Drawer
        open={isOpen}
        anchor="right"
        onClose={(e) => addTeam(false, e)}
        style={{ zIndex: 1300 }}
        classes={{ paper: classes.createTeam }}>
        <Typography variant="h4" component="h4">
          {!isEmpty(specificRowData) ? `Update Team` : `Create Team`}
        </Typography>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="ticketBody">
            <div className="ticketDropDown">
              <TextField
                id="one-textarea"
                fullWidth
                size="small"
                name="name"
                placeholder="Name"
                value={formik?.values?.name}
                onChange={(e) => {
                  formik?.handleChange(e);
                }}
                error={Boolean(formik?.touched?.name && formik?.errors?.name)}
                helperText={formik?.touched?.name && formik?.errors?.name}
              />
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                options={employees}
                getOptionLabel={(option) => option.name || 'No name'}
                value={formik.values.feManager}
                onChange={(event, value) =>
                  formik.setFieldValue('feManager', value)
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="FE Manager"
                    error={
                      formik.touched.feManager &&
                      Boolean(formik.errors.feManager)
                    }
                    helperText={
                      formik.touched.feManager && formik.errors.feManager
                    }
                  />
                )}
              />
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                options={employees}
                getOptionLabel={(option) => option.name || 'No name'}
                value={formik.values.feTeamLead}
                onChange={(event, value) =>
                  formik.setFieldValue('feTeamLead', value)
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="FE Team Lead"
                    error={
                      formik.touched.feTeamLead &&
                      Boolean(formik.errors.feTeamLead)
                    }
                    helperText={
                      formik.touched.feTeamLead && formik.errors.feTeamLead
                    }
                  />
                )}
              />
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                options={employees}
                getOptionLabel={(option) => option.name || 'No name'}
                value={formik.values.beManager}
                onChange={(event, value) =>
                  formik.setFieldValue('beManager', value)
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="BE Manager"
                    error={
                      formik.touched.beManager &&
                      Boolean(formik.errors.beManager)
                    }
                    helperText={
                      formik.touched.beManager && formik.errors.beManager
                    }
                  />
                )}
              />
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                options={employees}
                getOptionLabel={(option) => option.name || 'No name'}
                value={formik.values.beTeamLead}
                onChange={(event, value) =>
                  formik.setFieldValue('beTeamLead', value)
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="BE Team Lead"
                    error={
                      formik.touched.beTeamLead &&
                      Boolean(formik.errors.beTeamLead)
                    }
                    helperText={
                      formik.touched.beTeamLead && formik.errors.beTeamLead
                    }
                  />
                )}
              />
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                options={employees}
                getOptionLabel={(option) => option.name || 'No name'}
                value={formik.values.projectManager}
                onChange={(event, value) =>
                  formik.setFieldValue('projectManager', value)
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Project Manager"
                    error={
                      formik.touched.projectManager &&
                      Boolean(formik.errors.projectManager)
                    }
                    helperText={
                      formik.touched.projectManager &&
                      formik.errors.projectManager
                    }
                  />
                )}
              />
            </div>
            {/* <Box display="flex" alignSelf="flex-end" mt="auto"> */}
            <Box display="flex" alignSelf="flex-end" mt={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => closeDrawer()}
                sx={{ marginLeft: 'auto', height: '38px' }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                sx={{ height: '38px', ml: 1 }}
                disabled={formik.isSubmitting}>
                {!isEmpty(specificRowData) ? 'Update' : 'Create'}
              </Button>
            </Box>
            {!isEmpty(specificRowData) && (
              <Box marginTop={2}>
                <TeamsTable
                  columns={memberColumns}
                  rows={specificRowData?.members || []}
                  isMembersTable
                />
              </Box>
            )}
          </div>
        </form>
      </Drawer>
    </div>
  );
};

export default AddTeam;
