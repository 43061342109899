import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import parse from 'src/utils/parse';

class BillingApi {


    async getAllRelease(args: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_CASES}/billingAccount${args ? `?${args}` : ''}`).then((response) => {
                response = parse(response);
                if (!isUndefined(response?.data)) {
                    resolve(response);
                } else {
                    reject(new Error('internal Server Error'));
                }
            })
                .catch(() => {
                    reject(new Error('internal Server Error'));
                });
        });
    }
    // https://erp-it22-support-dev.apps.alpha.it22.cloud/billingAccount?page=0&pageSize=25&projectId=66aa0ce001c1f91c09c0abee

    async getAllBillingAccountsByProject(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_CASES}/billingAccount?page=0&pageSize=1000&projectId=${id}`).then((response) => {
                response = parse(response);
                if (!isUndefined(response?.data)) {
                    resolve(response);
                } else {
                    reject(new Error('internal Server Error'));
                }
            })
                .catch(() => {
                    reject(new Error('internal Server Error'));
                });
        });
    }


    async getAllBillingAccounts(args: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_CASES}/billingAccount${args ? `?${args}` : ''}`).then((response) => {
                response = parse(response);
                if (!isUndefined(response?.data)) {
                    resolve(response);
                } else {
                    reject(new Error('internal Server Error'));
                }
            })
                .catch(() => {
                    reject(new Error('internal Server Error'));
                });
        });
    }

    async createBillingAccount(payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_CASES}/billingAccount`, payload)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async updateBillingAccount(id: any, payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_CASES}/billingAccount/${id}`, payload)
                .then((response) => {
                    response = parse(response);
                    if (!isUndefined(response?.data)) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}

export const billingApi = new BillingApi();
