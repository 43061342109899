import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import TeamsTable from './teams-table';
import AddTeam from './add-team';
import { teamsApi } from 'src/api/teams';
import SearchBar from 'src/components/widgets/searchBar/SearchBar';
import toast from 'react-hot-toast';

const Teams = () => {
  const [teamsData, setTeamsData] = useState([]);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState('');
  const [specificRowData, setSpecificRowData] = useState({});

  const fetchData = async () => {
    try {
      // Simulating an API call
      const response = await teamsApi.getAllTeams({ page, pageSize, search });
      setTeamsData(response?.data?.data);
      setTotalRecords(response?.data?.totalRecords);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const columns = [
    {
      id: 'name',
      label: 'Name',
      width: '8%',
      maxWidth: '8%',
      render: (row) => (
        <Typography variant="body2">{row.name || '-'}</Typography>
      ),
    },
    {
      id: 'feManager',
      label: 'FE Manager',
      width: '8%',
      maxWidth: '8%',
      render: (row) => (
        <Typography variant="body2">{row.feManager?.name || '-'}</Typography>
      ),
    },
    {
      id: 'feTeamLead',
      label: 'FE Team Lead',
      width: '8%',
      maxWidth: '8%',
      render: (row) => (
        <Typography variant="body2">{row.feTeamLead?.name || '-'}</Typography>
      ),
    },
    {
      id: 'beManager',
      label: 'BE Manager',
      width: '8%',
      maxWidth: '8%',
      render: (row) => (
        <Typography variant="body2">{row.beManager?.name || '-'}</Typography>
      ),
    },
    {
      id: 'beTeamLead',
      label: 'BE Team Lead',
      width: '8%',
      maxWidth: '8%',
      render: (row) => (
        <Typography variant="body2">{row.beTeamLead?.name || '-'}</Typography>
      ),
    },
    {
      id: 'projectManager',
      label: 'Project Manager',
      width: '8%',
      maxWidth: '8%',
      render: (row) => (
        <Typography variant="body2">
          {row.projectManager?.name || '-'}
        </Typography>
      ),
    },
    {
      id: 'members',
      label: 'Members',
      width: '8%',
      maxWidth: '8%',
      render: (row) =>
        row.members?.length > 0 ? (
          <Typography variant="body2">{row?.members?.length}</Typography>
        ) : (
          <Typography variant="body2">0</Typography>
        ),
    },
    {
      id: 'isActive',
      label: 'Status',
      width: '8%',
      maxWidth: '8%',
      render: (row) => (
        <Button
          sx={{
            background: row?.isActive
              ? 'rgba(7, 83, 0, 0.1)'
              : 'rgba(255, 161, 161, 0.3)',
            color: row?.isActive ? 'rgba(29, 122, 21, 1)' : 'red',
            width: '70px',
            fontSize: '12px',
          }}
          onClick={async (e) => {
            e.stopPropagation();
            try {
              await teamsApi.changeTeamStatus(row?._id);
              toast.success(`Team Status updated successfully!`);
              fetchData();
            } catch (err) {
              toast.error(`Something went wrong!`);
            }
          }}>
          {row?.isActive ? 'Active' : 'Inactive'}
        </Button>
      ),
    },
  ];

  const addTeam = useCallback(
    async (open: boolean, event: any) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawer(open);
      if (open === false) {
        setSpecificRowData({});
      }
    },
    [drawer]
  );

  const handleCloseDrawer = () => {
    setDrawer(!drawer);
    setSpecificRowData({});
  };
  const handleSearch = (val) => {
    setSearch(val);
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, search]);

  const singleRowHandler = (row: any) => {
    setSpecificRowData(row);
    setDrawer(true);
  };

  return (
    <>
      <Box p={2}>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography color="textPrimary" variant="h3">
            Teams
          </Typography>

          <Box display="flex" justifyContent="space-between">
            <SearchBar
              onSearch={handleSearch}
              value={search}
              // anyState={autoRefresh !== 0}
            />
            <Button
              sx={{ height: '30px', fontSize: '14px', marginLeft: '10px' }}
              variant="contained"
              color="secondary"
              onClick={(e) => addTeam(true, e)}>
              Add Team
            </Button>
          </Box>
        </Box>

        <TeamsTable
          columns={columns}
          rows={teamsData}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalRecords={totalRecords}
          totalPages={totalPages}
          singleRowHandler={singleRowHandler}
        />
      </Box>
      {drawer && (
        <AddTeam
          isOpen={drawer}
          addTeam={addTeam}
          closeDrawer={handleCloseDrawer}
          fetchData={fetchData}
          specificRowData={specificRowData}
        />
      )}
    </>
  );
};

export default Teams;
