import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import WhiteLabelSection from 'src/components/organization/white_label/WhiteLabelSection';
import { organizationApi } from 'src/api/organizationApi';
import logger from 'src/utils/logger';
import { permissionsApi } from 'src/api/permissions';
import toast from 'react-hot-toast';
import { PermissionsForm } from './PermissionForm';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    selectPermissionsCard: {
      display: 'flex',
      marginTop: '22px',
      '& .tabs': {
        flexGrow: 1,
        '& .allTabs': {
          textTransform: 'capitalize !important',
        },
        '& .MuiTab-root': {
          textTransform: 'none',
        },
        '& .MuiTab-wrapper': {
          alignItems: 'self-start',
        },
      },
    },
    indicator: {
      left: '0px',
    },
    btn: {
      width: '82px',
      marginLeft: '9px',
    },
    permissionBoxesWrapper: {
      padding: '10px 27px 20px',
      borderRadius: '4px',
      boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
      backgroundColor: '#FFF',
    },
    activeTab: {
      borderLeft: `4px solid ${theme.palette.secondary.main}`,
      backgroundColor: 'transparent !important',
      color: `${theme.palette.primary.dark} !important`,
    },
  })
);

const GeneralSettings = () => {
  const [selectedModuleIndex1, setSelectedModuleIndex1] = useState(0); // Admin tab index
  const [selectedModuleIndex2, setSelectedModuleIndex2] = useState(0); // User tab index
  const [clientData, setClientData] = useState([]);
  const [permissionsData, setPermissionsData] = useState([]);
  const [adminRolePermissions, setAdminRolePermissions] = useState([]); // Admin permissions
  const [userRolePermissions, setUserRolePermissions] = useState([]); // User permissions
  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  const handleTabChange1 = (event, newIndex) => {
    setSelectedModuleIndex1(newIndex); // Handle tab change for admin
  };

  const handleTabChange2 = (event, newIndex) => {
    setSelectedModuleIndex2(newIndex); // Handle tab change for user
  };

  const getClientRoles = async () => {
    setLoading(true);
    try {
      const [clientRes, permissionsRes] = await Promise.all([
        organizationApi.getClientData(),
        permissionsApi.getPermissionsGroup(),
      ]);
      setClientData(clientRes?.data?.data);
      setPermissionsData(permissionsRes?.data);
    } catch (error) {
      logger(error, 'error');
    } finally {
      setLoading(false); // Stop loading after data is fetched
    }
  };

  const getAdminRolePermissions = async (roleId) => {
    try {
      const res = await permissionsApi.getRolePermissions(roleId);
      setAdminRolePermissions(res?.data?.permissions || []);
    } catch (error) {
      logger(error, 'error');
      toast.error('Failed to fetch admin role permissions');
    }
  };

  const getUserRolePermissions = async (roleId) => {
    try {
      const res = await permissionsApi.getRolePermissions(roleId);
      setUserRolePermissions(res?.data?.permissions || []);
    } catch (error) {
      logger(error, 'error');
      toast.error('Failed to fetch user role permissions');
    }
  };

  const handleSubmit = async (values, { setSubmitting }, roleId) => {
    setSubmitting(true);
    try {
      const { permissions } = values;
      await permissionsApi.updateRolesPermission(roleId, { permissions });
      toast.success(`Permissions updated successfully`);
      logger('Permissions updated successfully', 'success');
    } catch (error) {
      logger(error, 'error');
      toast.error(`Something went wrong`);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    getClientRoles();
  }, []);

  useEffect(() => {
    if (clientData[0]?.id) {
      getAdminRolePermissions(clientData[0].id);
    }
    if (clientData[1]?.id) {
      getUserRolePermissions(clientData[1].id);
    }
  }, [clientData]);

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12}>
        <Typography variant="h3">Modules Permission</Typography>
      </Grid>

      <Grid item xs={12}>
        <PermissionsForm
          role={clientData[0]}
          initialValues={{ permissions: adminRolePermissions }}
          handleSubmit={(values, actions) =>
            handleSubmit(values, actions, clientData[0]?.id)
          }
          selectedModuleIndex={selectedModuleIndex1}
          handleTabChange={handleTabChange1}
          permissionsData={permissionsData}
          classes={classes}
          isLoading={loading}
        />
      </Grid>

      <Grid item xs={12}>
        <PermissionsForm
          role={clientData[1]}
          initialValues={{ permissions: userRolePermissions }}
          handleSubmit={(values, actions) =>
            handleSubmit(values, actions, clientData[1]?.id)
          }
          selectedModuleIndex={selectedModuleIndex2}
          handleTabChange={handleTabChange2}
          permissionsData={permissionsData}
          classes={classes}
          isLoading={loading}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" mb={2} mt={1}>
          White Labeling
        </Typography>
        <WhiteLabelSection />
      </Grid>
    </Grid>
  );
};

export default GeneralSettings;
