import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  IconButton,
  Autocomplete,
  TextField,
  Link,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import clsx from 'clsx';
import { CircularProgress } from '@material-ui/core';

interface Option {
  id: number;
  name: string;
}

interface Input {
  id: number;
  value: string;
  label: string;
  caseKey: string;
}

interface LinkCasesProps {
  inputs: Input[];
  onAddInput: () => void;
  onRemoveInput: (id: number) => void;
  onInputChange: (id: number, newValue: Option | null) => void;
  onLabelChange: (id: number, newValue: Option | null) => void;
  labelOptions: Option[]; // Options for label autocomplete (array of objects)
  valueOptions: any[]; // Options for value autocomplete (array of objects)
  placeholder?: string;
  labelPlaceholder?: string;
  mainClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
}

const LinkCases: React.FC<LinkCasesProps> = ({
  inputs,
  onAddInput,
  onRemoveInput,
  onInputChange,
  onLabelChange,
  labelOptions,
  valueOptions,
  placeholder,
  labelPlaceholder,
  mainClassName,
  inputClassName,
  labelClassName,
  disabled,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isStacked, setIsStacked] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setIsStacked(containerRef.current.offsetWidth < 600);
      }
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isAddButtonDisabled = (input: Input): boolean =>
    !(input.label && input.value); // Disable if label or value is empty

  const handleInputChange = (id: number, newValue: any) => {
    onInputChange(id, newValue);
  };

  const renderInputRow = (input: Input, isLast: boolean) => (
    <Box
      key={input.id}
      display="flex"
      flexDirection={isStacked ? 'row' : 'row'}
      alignItems={isStacked ? 'flex-start' : 'center'}
      marginBottom={2}
      style={{
        marginTop: '8px',
        borderRadius: '5px',
        marginBottom: '0px',
      }}>
      {isLast && (
        <Autocomplete
          options={labelOptions}
          getOptionLabel={(option) => option.name} // Display the name property
          value={labelOptions.find((opt) => opt.name === input.label) || null}
          onChange={(event, newValue) => onLabelChange(input.id, newValue)}
          sx={{
            width: '90% !important',
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={clsx('labelClassName', labelClassName)}
              variant="outlined"
              placeholder={labelPlaceholder}
              disabled={disabled}
              InputProps={{
                ...params.InputProps,
                sx: {
                  height: '25px !important',
                  width: '90% !important',

                  '& input': {
                    fontSize: '12px',
                    height: 'auto !important',
                  },
                },
              }}
            />
          )}
          style={{
            marginRight: isStacked ? 0 : 10,
            marginBottom: isStacked ? 5 : 0,
            flex: 1,
          }}
        />
      )}
      {isLast ? (
        <Autocomplete
          freeSolo
          options={valueOptions}
          getOptionLabel={(option: any) => option.summary} // Display the name property
          value={
            valueOptions.find((opt) => opt.summary === input.value) || null
          }
          onChange={(event, newValue: any) =>
            handleInputChange(input.id, newValue)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              className={clsx('inputClassName', inputClassName)}
              variant="outlined"
              placeholder={placeholder}
              disabled={disabled}
              InputProps={{
                ...params.InputProps,
                sx: {
                  height: '25px !important',
                  '& input': {
                    fontSize: '12px',
                    height: 'auto !important',
                  },
                },
              }}
            />
          )}
          style={{
            marginRight: isStacked ? 0 : 10,
            marginBottom: isStacked ? 10 : 0,
            flex: 1,
          }}
        />
      ) : (
        <Box
          sx={{
            flex: 1,
          }}>
          <Typography variant="h5" sx={{ fontSize: '12px', fontWeight: 600 }}>
            {input.label}
          </Typography>
          <Link
            href={`/erp/cases/${input.caseKey}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginRight: 10,
              marginTop: 1,
              width: '100%',
              fontSize: '12px',
              cursor: 'pointer',
              padding: !isLast && '6px 10px 1px',
              backgroundColor: !isLast && 'rgba(237, 237, 237, 0.2)',
            }}>
            <Typography sx={{ mr: 1, fontSize: '12px', fontWeight: 500 }}>
              {input?.caseKey}
            </Typography>
            {input.label ? `${input.label}-${input.value}` : input.value}
          </Link>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection={isStacked ? 'row' : 'column'}
        alignSelf="flex-end">
        {isLast && (
          <IconButton
            color="primary"
            onClick={onAddInput}
            disabled={isAddButtonDisabled(input)} // Disable the button based on label and value
            title="Add New"
            style={{
              marginRight: isStacked ? 2 : 0,
              marginBottom: 10,
              height: '25px',
            }}>
            <AddIcon sx={{ fontSize: 14 }} />
          </IconButton>
        )}
        {!isLast && inputs.length > 1 && (
          <IconButton
            color="secondary"
            title="Remove Attachment"
            onClick={() => onRemoveInput(input.id)}
            style={{
              height: '25px',
            }}>
            <ClearIcon sx={{ fontSize: 14 }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
  if (inputs?.length === 0) {
    return (
      <Typography
        sx={{
          mr: 1,
          fontSize: '12px',
          fontWeight: 500,
          textAlign: 'center',
          marginTop: 1,
        }}>
        Loading...
      </Typography>
    );
  }
  return (
    <Box className={mainClassName} ref={containerRef}>
      {inputs.map((input, index) =>
        renderInputRow(input, index === inputs.length - 1)
      )}
    </Box>
  );
};

export default LinkCases;
