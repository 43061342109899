/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import moment from 'moment';
import { casesApi } from 'src/api/casesApi';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { organizationApi } from 'src/api/organizationApi';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    CreateCase: {
      width: '40%',
      padding: '5px 20px ',
      zIndex: 1300,
      border: 'none',

      '& .setDateLabel': {
        position: 'relative',
        '& label': {
          position: 'absolute',
          zIndex: '11',
          fontSize: '12px',
          left: '10px',
          top: '-10px',
          background: 'white',
        },
      },
      '& errorDate': {
        color: '#000',
        fontFamily: 'Quicksand, sans- serif',
        fontWeight: '400',
        fontSize: '0.75rem',
        lineHeight: '1.66',
        textAlign: 'left',
        marginTop: '3px',
        marginRight: '14px',
        marginBottom: '0',
        marginLeft: '14px',
      },
      '& .react-datepicker-wrapper': {
        width: '100%',
        '& input': {
          border: 'solid 1px #b8bec8',
          borderRadius: '5px',
          fontFamily: 'Quicksand,sans-serif',
        },
      },
      '& .ticketBody': {
        height: '90vh',
        overflowY: 'auto',
        padding: '0 20px',
        '& input': {
          borderRadius: '2px',
        },
      },
      '& h4': {
        background: '#C22027',
        color: '#fff',
        padding: '0 40px',
        margin: '-5px -20px 30px',
        height: '74px',
        display: 'flex',
        alignItems: 'center',
      },
      '& label': {
        letterSpacing: ' 0.15px',
        color: '#231F20',
        fontWeight: '600',
        display: 'block',
      },

      '& select, & .MuiAutocomplete-inputRoot, & input': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        '& .MuiAutocomplete-input': {
          padding: 0,
        },
      },
      '& .customField': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        '& .MuiChip-root': {
          border: 0,
          borderRadius: '4px',
          margin: '2px 0',
          background: 'rgb(241 240 240)',
        },
      },
      '& .labelsDataCss .MuiAutocomplete-inputRoot': {
        height: 'initial',
      },
      '& .ticketDropDown+.ticketDropDown': {
        marginTop: '15px',
      },
      '& .submitTicket ': {
        margin: '35px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      '& .submitTicket button+button': {
        marginLeft: '15px',
      },
      '& .alignBtn': {
        marginTop: '55px',
      },
    },

    datePicker: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

const StartSprintDrawer: FC<any> = ({
  isOpen,
  closeDrawer,
  getAllData,
}: any) => {
  const classes = useStyles();
  const [allProjects, setAllProjects] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(1); // Initialize with 1 week or existing value

  const getAllProjects = async () => {
    try {
      const response = await casesApi.getAllProjects();
      if (response?.status === 200) {
        setAllProjects(response?.data);
      }
    } catch (error) {
      toast.error(error.message);
      setAllProjects([]);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      project: [],
      startDate: '',
      endDate: '',
      goal: '',
      allocatedHours: '',
      duration: 1,
    },
    onSubmit: async (values, actions) => {
      try {
        const payload = {
          ...values,
          duration: `${values.duration} week`,
          startDate: moment(values.startDate).format('YYYY-MM-DD'),
          endDate: moment(values.endDate).format('YYYY-MM-DD'),
        };
        const response = await organizationApi.createSprintOrganization(
          payload
        );
        setIsSubmitting(true);
        if (response?.status === 201) {
          toast.success('Sprint Created Successfully');
          closeDrawer();
          actions.resetForm();
          getAllData();
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsSubmitting(false);
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required('Value is required'),
      goal: Yup.string().trim().required('Value is required'),
      allocatedHours: Yup.number()
        .min(1, 'Allocated hours must be at least 1')
        .max(100, 'Allocated hours must be at most 100')
        .required('Allocated hours are required'),
      project: Yup.array()
        .min(1, 'At least one project must be selected')
        .of(
          Yup.object().shape({
            name: Yup.string().required('Project name is required'),
          })
        )
        .required('Project is required'),
      startDate: Yup.string().trim().required('Value is required'),
      endDate: Yup.string().trim().required('Value is required'),

      duration: Yup.string().trim().required('Value is required'), // Ensure correct initial value
    }),
  });

  const addWeeksToDate = (dateStr, weeks) => {
    const date = dayjs(dateStr);
    const newDate = date.add(weeks, 'week');
    return newDate.format('YYYY-MM-DD');
  };

  const handleDurationChange = (event) => {
    const newDuration = event.target.value;
    setDuration(newDuration);
    formik.setFieldValue('duration', newDuration);
    formik.setFieldValue(
      'endDate',
      addWeeksToDate(formik.values.startDate, newDuration)
    );
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  return (
    <div className="createTicket">
      <Drawer
        open={isOpen}
        anchor="right"
        onClose={closeDrawer}
        classes={{ paper: classes.CreateCase }}>
        <Typography variant="h4" component="h4">
          Create Sprint
        </Typography>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="ticketBody">
            <div className="ticketDropDown">
              <TextField
                value={formik?.values?.name}
                variant="outlined"
                name="name"
                fullWidth
                onChange={(e) => {
                  formik?.handleChange(e);
                }}
                inputProps={{ maxLength: 250 }}
                placeholder="Name"
                error={Boolean(formik?.touched?.name && formik?.errors?.name)}
                helperText={formik?.touched?.name && formik?.errors?.name}
              />
            </div>
            <div className="ticketDropDown">
              <TextField
                value={formik?.values?.goal}
                variant="outlined"
                name="goal"
                fullWidth
                onChange={(e) => {
                  formik?.handleChange(e);
                }}
                inputProps={{ maxLength: 250 }}
                placeholder="Goal"
                error={Boolean(formik?.touched?.goal && formik?.errors?.goal)}
                helperText={formik?.touched?.goal && formik?.errors?.goal}
              />
            </div>

            <div className="ticketDropDown">
              <TextField
                value={formik.values?.allocatedHours}
                variant="outlined"
                name="allocatedHours"
                type="number"
                fullWidth
                onChange={formik.handleChange}
                placeholder="Allocated Hours"
                error={Boolean(
                  formik.errors.allocatedHours && formik.touched.allocatedHours
                )}
                helperText={
                  formik.touched.allocatedHours && formik.errors.allocatedHours
                }
              />
            </div>

            <div className="ticketDropDown">
              <div className="setDateLabel">
                <label className="">Start Date</label>
                <DatePicker
                  label="Start Date"
                  selected={
                    formik.values.startDate
                      ? new Date(formik.values.startDate)
                      : null
                  }
                  onChange={(date) =>
                    formik.setFieldValue(
                      'startDate',
                      date ? dayjs(date).format('YYYY-MM-DD') : ''
                    )
                  }
                  dateFormat="dd-MM-yyy"
                  placeholderText="dd-MM-yyy"
                />
                {formik.errors.startDate && formik.touched.startDate ? (
                  <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-10nl1tw-MuiFormHelperText-root">
                    Value is required
                  </p>
                ) : null}
              </div>
            </div>

            <div className="ticketDropDown">
              <div className="setDateLabel">
                <label className="">End Date</label>
                <DatePicker
                  label="End Date"
                  selected={
                    formik.values.endDate
                      ? new Date(formik.values.endDate)
                      : null
                  }
                  onChange={(date) =>
                    formik.setFieldValue(
                      'endDate',
                      date ? dayjs(date).format('YYYY-MM-DD') : ''
                    )
                  }
                  dateFormat="dd-MM-yyy"
                  placeholderText="dd-MM-yyy"
                />
                {formik.errors.endDate && formik.touched.endDate ? (
                  <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-10nl1tw-MuiFormHelperText-root">
                    Value is required
                  </p>
                ) : null}
              </div>
            </div>

            <div className="ticketDropDown">
              <Select
                sx={{ width: '100%', maxHeight: '38px' }}
                id="duration"
                name="duration"
                value={formik.values.duration || duration} // Use Formik's value
                onChange={handleDurationChange}>
                {[1, 2, 3, 4].map((week) => (
                  <MenuItem key={week} value={week}>
                    {week} week
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="ticketDropDown">
              <Autocomplete
                multiple
                limitTags={1}
                id="project-name"
                options={allProjects || []}
                value={formik?.values?.project || []}
                onChange={(event: any, value: any) => {
                  formik?.setFieldValue('project', value);
                }}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Projects"
                    error={Boolean(
                      formik.errors.project && formik.touched.project
                    )}
                    sx={{
                      height: 'auto',
                      minHeight: '38px',
                      '& .MuiInputBase-root': { height: 'auto !important' },
                    }} // Set height to auto and minimum height
                    helperText={formik.touched.project && formik.errors.project}
                  />
                )}
              />
            </div>

            <div className="submitTicket alignBtn">
              <Box display="flex">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => closeDrawer()}
                  sx={{ marginLeft: 'auto', height: '38px' }}>
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  sx={{ height: '38px' }}
                  disabled={isSubmitting}>
                 Create Sprint
                </Button>
              </Box>
            </div>
          </div>
        </form>
      </Drawer>
    </div>
  );
};

export default StartSprintDrawer;
