import { Drawer, Typography } from '@material-ui/core';
import { ReactNode, MouseEvent } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    createFormSideBar: {
      width: '30%',
      padding: '5px 20px ',
      zIndex: 1300,
      border: 'none',
      '& .ticketBody': {
        height: '90vh',
        overflowY: 'auto',
        padding: '5px 20px',
        '& input': {
          borderRadius: '2px',
        },
      },
      '& h4': {
        background: '#C22027',
        color: '#fff',
        padding: '10px 40px',
        margin: '-5px -20px 30px',
        height: '64px',
        display: 'flex',
        alignItems: 'center',
      },
      '& label': {
        letterSpacing: ' 0.15px',
        color: '#231F20',
        fontWeight: '600',
        display: 'block',
      },

      '& select, & .MuiAutocomplete-inputRoot, & input': {
        // height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        '& .MuiAutocomplete-input': {
          padding: 0,
        },
      },
      '& .MuiSelect-root': {
        height: '38px',
      },
      '& .customField': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        '& .MuiChip-root': {
          border: 0,
          borderRadius: '4px',
          margin: '2px 0',
          background: 'rgb(241 240 240)',
        },
      },
      '& .labelsDataCss .MuiAutocomplete-inputRoot': {
        height: 'initial',
      },
      '& .ticketDropDown+.ticketDropDown': {
        marginTop: '15px',
      },
      '& .submitTicket ': {
        margin: '35px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      '& .submitTicket button+button': {
        marginLeft: '15px',
      },
    },
    datePicker: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '& .ticketDropDown+.ticketDropDown': {
      marginTop: '15px',
    },
    '& .submitTicket ': {
      margin: '35px 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .submitTicket button+button': {
      marginLeft: '15px',
    },
  })
);
interface CreateFormSideBarProps {
  isOpen: boolean;
  closeDrawer?: (shouldClose: boolean, event: MouseEvent) => void;
  children?: ReactNode;
  title?: string;
  customClass?: string;
  BackdropProps?: boolean;
}

const CreateFormSideBar = ({
  title,
  children,
  isOpen,
  closeDrawer,
  customClass,
  BackdropProps,
}: CreateFormSideBarProps) => {
  const classes = useStyles();

  return (
    <div>
      <Drawer
        open={isOpen}
        className={customClass}
        anchor="right"
        BackdropProps={{ invisible: BackdropProps }}
        onClose={(e: MouseEvent) => closeDrawer(false, e)}
        classes={{ paper: classes.createFormSideBar }}>
        <Typography variant="h4" component="h4">
          {title}
        </Typography>
        {children}
      </Drawer>
    </div>
  );
};

export default CreateFormSideBar;
