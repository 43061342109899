import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import moment from 'moment';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { TableProps } from 'src/types/components/tableList.types';
import Scrollbar from '../../Scrollbar';
// import logger from 'src/utils/logger';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const useStyles = makeStyles<Theme>(() => createStyles({
  btn: {
    width: '47px',
    height: '40px',
    padding: 0,
    minWidth: 'initial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    color: '#fff',
    marginLeft: '13px',
    '& .MuiButton-iconSizeMedium': {
      margin: '0',
    },
  },
  clip: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '10ch'
  },
  textFieldSelect: {
    width: '230px',
    marginLeft: '13px',
  },
  textFieldSelectRoot: {
    borderRadius: '4px',
    height: '38px',
  },
  tabButton: {
    width: '181px',
  },
  cardRoot: {
    borderRadius: '4px',
  },
  tabs: {
    '& .MuiTab-root': {
      backgroundColor: 'transparent',
      minWidth: '120px',
    },
    '& .Mui-selected': {
      backgroundColor: '#231F20',
      color: '#fff',
    },
  },
  tableHead: {
    '& .MuiTableCell-root': {
      textTransform: 'capitalize',
    },
  },
  tableRow: {
    '& :hover': {
      cursor: 'pointer',
    },
    '& .ticketStatusTrue': {
      backgroundColor: 'rgba(7, 83, 0, 0.16)',
      color: '#1D7A15',
      padding: '5px',
      fontWeight: 'Bold',
    },
    '& .ticketStatusFalse': {
      background: '#DEEBFF',
      color: '#3375D7',
      padding: '5px',
      fontWeight: 'Bold',
    },
  },
  priorityComponent: {
    height: '30px',
    background: 'rgba(189,189,189,0.1)',
    color: '#000000aa',
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    textTransform: 'capitalize',
    justifyContent: 'center'
  },
  loaderTable: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '2222'
  },
}));

const TableHeadComponent: FC<any> = ({ data, setSelectedSort, selectedSort }: any) => (
  <TableHead sx={{ marginBottom: '1rem' }}>
    <TableRow>
      {data?.map((col: any) => (
        <TableCell
          align="center"
          className="textBold"
          sortDirection={selectedSort?.key === col?.id ? selectedSort?.value : false}
          title={col?.toolTip ? col?.summary : null}
        >
          {
            col.isSort
              ? (
                <>
                  <TableSortLabel
                    active={selectedSort?.key === col?.id}
                    direction={selectedSort?.key === col?.id ? selectedSort?.value : 'asc'}
                    // active={col.isSort}
                    // direction={col.direction}
                    onClick={() => {
                      col.toggleSortData(`${col.id}`);
                      // getAllSortedData(`${col.id}`, col.direction === 'asc' ? '1' : '-1');
                      // logger(getAllSortedData);
                      setSelectedSort({ key: `${col?.id}`, value: col.direction === 'asc' ? 'asc' : 'desc' });
                      // setSort('', '');
                    }}
                  // hideSortIcon={col.isSort || true}
                  >
                    {col?.label}
                  </TableSortLabel>
                </>
              )
              : col?.label
          }
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const PriorityComponent: FC<any> = ({ label }: any) => {
  const classes = useStyles();
  const styling: any = {
    icon: <RadioButtonUncheckedIcon />
  };

  switch (label?.toLowerCase()) {
    case 'highest':
      styling.icon = <DoubleArrowIcon sx={{ fontSize: '16px', color: '#f55033', transform: 'rotate(270deg)' }} />;
      break;
    case 'medium':
      styling.icon = <DragHandleIcon sx={{ fontSize: '24px', width: '18px', color: '#f79d28' }} />;
      break;
    case 'minor':
      styling.icon = <RadioButtonUncheckedIcon sx={{ fontSize: '14px' }} />;
      break;
    case 'blocker':
      styling.icon = <RemoveCircleIcon sx={{ fontSize: '16px', color: '#f55033' }} />;
      break;
    case 'high':
      styling.icon = <ExpandLessIcon sx={{ fontSize: '24px', color: '#f55033' }} />;
      break;
    case 'low':
      styling.icon = <ExpandMoreIcon sx={{ fontSize: '24px', color: '#3384f5' }} />;
      break;
    case 'lowest':
      styling.icon = <DoubleArrowIcon sx={{ fontSize: '16px', color: '#3384f5', transform: 'rotate(90deg)' }} />;
      break;
    default:
      styling.icon = label?.toLowerCase()?.indexOf('back') === -1 ? 'N/A' : '';
      break;
  }
  return (
    <>
      <Box
        className={classes.priorityComponent}
      >
        {styling.icon}
        {label?.toLowerCase()}
      </Box>
    </>
  );
};
const TableBodyComponent: FC<any> = ({ data, editRouteUrl, permissions, isProject, isOrganization, newTicketIds }: any) => {
  const classes = useStyles();
  // const navigate = useNavigate();
  // const handleRedirectNext = (id: string) => {
  //   navigate(`${editRouteUrl}/${id}`);
  // };
  // logger(handleRedirectNext);
  return (
    <>
      <TableBody style={{ position: 'relative' }}>
        {data?.length !== 0 ? (data?.map((row: any) => (
          <TableRow
            component={Link}
            to={(permissions?.update || permissions?.getById) ? `${editRouteUrl}/${row?.caseKey}` : ''}
            hover
            // onClick={() => { if ((permissions?.update || permissions?.getById)) { handleRedirectNext(row?.caseKey); } }}
            className={classes.tableRow}
            key={row?.id}
            style={{ border: newTicketIds.includes(row.id) ? '2px solid #87c387' : 'transparent', textDecoration: 'none' }}
          >
            <TableCell
              align="left"
              size="small"
              sx={{ padding: '8px 0' }}
            >
              {row?.attachmentId?.length ?
                <AttachFileIcon sx={{ fontSize: '18px' }} /> : null
              }
            </TableCell>
            <TableCell
              align="center"
            >
              <span
                className={classes.clip}
              >
                {row?.caseKey}
              </span>
            </TableCell>
            <TableCell
              align="center"
              title={row?.summary === null ? 'N/A' : row?.summary}
              sx={{ width: '22vw', maxWidth: '22vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <span>
                {row?.summary === null ? 'N/A' : row?.summary}

              </span>

            </TableCell>

            <TableCell align="center">
              <PriorityComponent label={row?.priority?.name} />
            </TableCell>

            <TableCell align="center">
              {row?.status?.name === null ? 'N/A' : row.status?.name}
            </TableCell>

            {
              isProject && (
                <TableCell
                  align="center"
                  title={isOrganization ? `${row?.organization?.name} - ${row?.project?.name}` : row?.project?.name}
                >
                  {row?.project?.name}
                </TableCell>
              )
            }

            <TableCell align="center">
              <strong>{row?.createdBy?.name}</strong>
            </TableCell>

            <TableCell align="center">
              {row?.assignee?.name ? <strong>{row?.assignee?.name}</strong> : 'N/A'}
            </TableCell>

            <TableCell
              align="center"
              title={row?.caseType?.name}
            >
              {row?.caseKey?.substring(0, 3)}
            </TableCell>

            {/* <TableCell
              align="center"
              title={row?.sla?.actualTime?.resolveTime === null ? null : moment(row?.sla?.actualTime?.resolveTime).format('MMM DD - hh:mm:ss')}
            >
              {row?.sla?.actualTime?.resolveTime === null ? 'N/A' : <strong>{moment(row?.sla?.actualTime?.resolveTime).format('MMM DD - hh:mm')}</strong>}
            </TableCell>

            <TableCell
              align="center"
              title={row?.sla?.actualTime?.firstResponseTime === null ? null : moment(row?.sla?.actualTime?.firstResponseTime).format('MMM DD - hh:mm:ss')}
            >
              {row?.sla?.actualTime?.firstResponseTime === null ? 'N/A' : <strong>{moment(row?.sla?.actualTime?.firstResponseTime).format('MMM DD - hh:mm')}</strong>}
            </TableCell> */}
            <TableCell
              align="center"
            >
              {moment(row?.createdAt).format('DD MMM, YYYY HH:mm:ss')}
            </TableCell>
          </TableRow>
        ))) : (
          <TableRow selected>
            <TableCell
              colSpan={12}
              sx={{ textAlign: 'center' }}
            >
              No Data Found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </>
  );
};

interface CaseListViewTypes extends TableProps {
  sortData?: any;
  toggleSortData?: any;
  setSelectedSort?: any;
  selectedSort?: any;
  isOrganization?: boolean;
  isProject?: boolean;
}
const CaseListView: FC<CaseListViewTypes> = ({
  data,
  editRouteUrl,
  count,
  page,
  limit,
  handlePage,
  bulkIds,
  handleLimit,
  handleAddBulkIds,
  handleRemoveBulkIds,
  sortData,
  toggleSortData,
  selectedSort,
  setSelectedSort,
  permissions,
  isOrganization = true,
  isProject = true,
  newTicketIds
}: any) => {
  const classes = useStyles();

  let Columns = [
    {
      id: 'attachment',
      label: '',
      isSort: false,
    },
    {
      id: 'id',
      label: 'Case #',
      isSort: true,
      toggleSortData,
      direction: sortData?.id
    },
    {
      id: 'title',
      label: 'Title',
      isSort: true,
      toggleSortData,
      direction: sortData?.caseName
    },
    {
      id: 'priority',
      label: 'Priority',
      isSort: true,
      toggleSortData,
      direction: sortData.priority
    },
    {
      id: 'status',
      label: 'Status',
      isSort: true,
      toggleSortData,
      direction: sortData.status
    },
    {
      id: 'project',
      label: 'Project',
      isSort: true,
      toggleSortData,
      direction: sortData.project
    },
    {
      id: 'reporter',
      label: 'Reporter',
      isSort: true,
      toggleSortData,
      direction: sortData?.createdBy
    },
    {
      id: 'assignee',
      label: 'Assignee',
      isSort: true,
      toggleSortData,
      direction: sortData?.assignee
    },
    {
      id: 'type',
      label: 'Type',
      isSort: true,
      toggleSortData,
      direction: sortData.caseType
    },
    {
      id: 'createdAt',
      label: 'Created At',
      isSort: true,
      toggleSortData,
      direction: sortData?.createdAt
    }
  ];
  if (!isOrganization) {
    Columns = Columns.filter((col) => col.id !== 'organization');
  }
  if (!isProject) {
    Columns = Columns.filter((col) => col.id !== 'project');
  }
  const handleChangePage = useCallback(
    (event: any, _page: number) => {
      handlePage(_page);
    },
    [page, data]
  );
  const handleChangeLimit = useCallback(
    (event: any) => {
      handleLimit(parseInt(event.target.value, 10));
      handlePage(0);
    },
    [limit, data]
  );

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        py: 1,
      }}
    >
      {' '}
      <Card className={classes.cardRoot}>
        <Scrollbar>
          <Box sx={{ minWidth: 700, px: 1, }}>
            <Table>
              <TableHeadComponent
                data={Columns}
                selectedSort={selectedSort}
                setSelectedSort={setSelectedSort}
              />
              <TableBodyComponent
                data={data}
                editRouteUrl={editRouteUrl}
                handleAddBulkIds={handleAddBulkIds}
                bulkIds={bulkIds}
                handleRemoveBulkIds={handleRemoveBulkIds}
                permissions={permissions}
                isProject={isProject}
                isOrganization={isOrganization}
                newTicketIds={newTicketIds}
              />

            </Table>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeLimit}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 100, 500, 1000]}
        />
      </Card>
    </Box>
  );
};

export default CaseListView;
