import {
  Box,
  Button,
  Checkbox,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { consultingRoleApi } from 'src/api/consultingRoleApi';
import logger from 'src/utils/logger';
import EditRolePopUp from './EditRolePopUp';
import CustomButton from 'src/components/widgets/buttons/CustomButton';
import toast from 'react-hot-toast';
import RestartAltIcon from '@material-ui/icons/RestartAlt';
import { casesApi } from 'src/api/casesApi';

const useStyles = makeStyles({
  root: {
    marginTop: '20px',
    width: '100%',
  },
  table: {
    margin: '10px 0',
    backgroundColor: 'white',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '10px',
  },
  editIcon: {
    display: 'inline-block',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardRoot: {
    marginBottom: '20px',
  },
  cardTitle: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
});

export const ClientSettingsMain = () => {
  const classes = useStyles();
  const [cuRoles, setCuRoles] = useState([]);
  const [cuRolesCopy, setCuRolesCopy] = useState([]);
  const { organizationID } = useParams(); // Get organizationID from the route params
  const [editRoleData, setEditRoleData] = useState({});
  const [editPopUp, setEditPopUp] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [caseTypeOptions, setCaseTypeOptions] = useState<any>([]);
  const [selectedCaseTypes, setSelectedCaseTypes] = useState<string[]>([]); // Store selected case type IDs

  const columns = [
    { name: 'Role', value: 'role' },
    { name: 'Cunit', value: 'cunit' },
  ];

  const handleChangePage = (event: any, _page: number) => {
    setPage(_page);
  };
  const handleChangeLimit = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handleEdit = (roleData: any) => {
    setEditRoleData(roleData);
    setEditPopUp(true);
  };

  const handlePopUpSubmit = (updatedRoleObject: any) => {
    const updatedCuRoleIndex = cuRoles.findIndex(
      (role) => role._id === updatedRoleObject._id
    );
    const updatedCuRoleState = [...cuRoles];
    updatedCuRoleState[updatedCuRoleIndex] = updatedRoleObject;
    setCuRoles(updatedCuRoleState);
  };

  const getAllCURoles = async () => {
    try {
      const response = await consultingRoleApi.getAllRoles(
        organizationID,
        page,
        limit
      );
      if (response?.status === 200) {
        setCuRoles(response?.data?.data);
        setCuRolesCopy(response?.data?.data);
        setCount(response?.data?.totalRecords);
      }
    } catch (error) {
      logger(error, 'error');
      setCuRoles([]);
      setCount(0);
    }
  };

  const handleBulkUpdateRoles = async () => {
    const payload = cuRoles.map((roleObj) => ({
      orgId: organizationID,
      role: roleObj.role,
      cunit: roleObj.cunit,
    }));
    try {
      const response = await consultingRoleApi.bulkUpdate(payload);
      if (response.status === 201) {
        toast.success(response.data);
        getAllCURoles();
      }
    } catch (error) {
      logger(error, 'error');
    }
  };

  const handleResetTable = () => {
    setCuRoles(cuRolesCopy);
  };

  const getCaseType = async () => {
    try {
      const response = await casesApi.getCaseTypes();
      setCaseTypeOptions(response?.data);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setCaseTypeOptions([]);
      }
    }
  };

  // Fetch pre-selected case types for the organization
  const getSelectedCaseTypes = async () => {
    try {
      const response = await casesApi.getOrganizationCaseTypes(organizationID);
      if (response.status === 200) {
        setSelectedCaseTypes(response.data || []); // Set initially selected case types
      }
    } catch (error) {
      logger(error, 'error');
      toast.error('Error fetching selected case types');
    }
  };

  const handleCheckboxChange = async (caseTypeId: string) => {
    const isSelected = selectedCaseTypes.includes(caseTypeId);
    let updatedSelectedCaseTypes;

    if (isSelected) {
      // Remove the case type if it was already selected
      updatedSelectedCaseTypes = selectedCaseTypes.filter(
        (id) => id !== caseTypeId
      );
    } else {
      // Add the case type if it wasn't selected
      updatedSelectedCaseTypes = [...selectedCaseTypes, caseTypeId];
    }

    setSelectedCaseTypes(updatedSelectedCaseTypes);

    try {
      const payload = {
        organizationId: organizationID,
        allowedCaseTypes: updatedSelectedCaseTypes, // Passing the selected case type IDs
      };
      // Call the API to update the selected case types for the organization
      const response = await casesApi.allowedCaseType(payload);

      if (response.status === 200) {
        toast.success('Case Types updated successfully');
      }
    } catch (error) {
      toast.error('Error updating Case Types');
      logger(error, 'error');
    }
  };

  useEffect(() => {
    getCaseType();  // Get all case types
    getSelectedCaseTypes(); // Fetch pre-selected case types when component mounts
  }, []);

  useEffect(() => {
    getAllCURoles();
  }, [limit, page]);

  return (
    <>
      <Box className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card className={classes.cardRoot}>
              <CardContent>
                <Typography variant="h4" className={classes.cardTitle}>
                  Case Type
                </Typography>
                {caseTypeOptions?.map((caseType) => (
                  <Box key={caseType.id} display="flex" alignItems="center">
                    <Checkbox
                      checked={selectedCaseTypes.includes(caseType.id)} // Reflect selected state
                      onChange={() => handleCheckboxChange(caseType.id)}
                      sx={{ p: 0 }}
                    />
                    <Typography
                      variant="h5"
                      sx={{ fontSize: '16px', ml: 1, mt: 0.5 }}>
                      {caseType.name}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className={classes.cardRoot}>
              <CardContent>
                <Typography variant="h4" className={classes.cardTitle}>
                  Others
                </Typography>
                <Box>
                  {/* Content for 'Others' card goes here */}
                  <Typography>
                    This section can contain any other relevant information.
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Typography variant="h4" fontWeight="bold">
          Cu Roles
        </Typography>
        <Box className={classes.table}>
          <Table>
            <TableHead>
              {columns.map((column) => (
                <TableCell key={column.value}>{column.name}</TableCell>
              ))}
              <TableCell />
            </TableHead>
            <TableBody>
              {cuRoles.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={columns?.length + 1}>
                    No data found
                  </TableCell>
                </TableRow>
              ) : (
                cuRoles.map((role) => (
                  <TableRow key={role._id}>
                    {columns.map((column) => (
                      <TableCell key={column.value}>
                        {role[column.value]}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Box
                        onClick={() => handleEdit(role)}
                        className={classes.editIcon}>
                        <img
                          src="/static/icons/editIcon.png"
                          alt="Edit Icons"
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={count}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeLimit}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 50, 100, 500, 1000]}
          />
        </Box>
        <Box className={classes.buttons}>
          <Button
            style={{ height: 30 }}
            onClick={handleResetTable}
            endIcon={<RestartAltIcon />}>
            Reset Table
          </Button>
          <CustomButton
            value="Update"
            onClick={handleBulkUpdateRoles}
            color="secondary"
          />
        </Box>
      </Box>
      {editPopUp && (
        <EditRolePopUp
          isOpen={editPopUp}
          data={editRoleData}
          setEditPopUp={setEditPopUp}
          handleSubmit={handlePopUpSubmit}
        />
      )}
    </>
  );
};
