import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import PriorityComponent from 'src/components/atoms/PriorityComponent';
import useAuth from 'src/hooks/useAuth';

const displayColumns = [
  {
    name: 'Case No',
    value: 'caseKey',
  },
  {
    name: 'Title',
    value: 'summary',
  },
  {
    name: 'Case Type',
    value: 'caseType',
  },
  {
    name: 'Priority',
    value: 'priority',
  },
  {
    name: 'Status',
    value: 'status',
  },
];

const DashboardTable = (props: any) => {
  const { recentCases } = props;
  const navigate = useNavigate();
  const { user } = useAuth();

  console.log(recentCases);

  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

  const produceStatusColor = (status) => {
    if (status === 'Done') return 'green';
    if (status === 'To Do') return 'red';
    return 'black';
  };

  const useStyles = makeStyles<Theme>(() =>
    createStyles({
      tableContainer: {
        overflowX: 'initial',
      },
      tableHead: {
        textAlign: 'center',
        '& .MuiTableCell-root': {
          backgroundColor: '#F4F4F4',
        },
      },
      tableRow: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    })
  );

  const classes = useStyles();

  const displayColumnValue = (recentCase, columnValue) => {
    if (typeof recentCase[columnValue] === 'object') {
      return recentCase[columnValue]?.name || 'N/A';
    }

    if (columnValue === 'summary') {
      const cleanSummary =
        recentCase[columnValue]?.replace(/<[^>]*>|&nbsp;/g, '') || 'N/A';

      if (cleanSummary.length > 90) {
        return (
          <Tooltip title={cleanSummary} placement="top">
            <div>{`${cleanSummary.slice(0, 90)} ...`}</div>
          </Tooltip>
        );
      }

      return cleanSummary;
    }

    return recentCase[columnValue] || 'N/A';
  };

  const handleSort = (columnValue) => {
    let direction = 'asc';
    if (sortConfig.key === columnValue && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: columnValue, direction });
  };

  const getColumnValueForSort = (caseItem, columnValue) => {
    // Sort status and priority by rank
    if (columnValue === 'status' || columnValue === 'priority') {
      return caseItem[columnValue]?.rank || 0; // Return rank or default to 0 if rank is missing
    }

    // For other objects with names (e.g., caseType), sort by name
    if (
      typeof caseItem[columnValue] === 'object' &&
      caseItem[columnValue]?.name
    ) {
      return caseItem[columnValue].name.toLowerCase();
    }

    return caseItem[columnValue]?.toString().toLowerCase() || ''; // Fallback for other values
  };

  const sortedCases = [...recentCases].sort((a, b) => {
    const aValue = getColumnValueForSort(a, sortConfig.key);
    const bValue = getColumnValueForSort(b, sortConfig.key);

    if (aValue < bValue) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const getSortDirectionIndicator = (columnValue) => {
    if (sortConfig.key === columnValue) {
      return sortConfig.direction === 'asc' ? '↑' : '↓';
    }
    return '';
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead className={classes.tableHead}>
          {displayColumns.map((heading) => (
            <TableCell
              key={heading.value}
              align="center"
              onClick={() => handleSort(heading.value)}
              sx={{
                fontWeight: 600,
                padding: '8px 0px 8px 16px',
                cursor: 'pointer',
              }}>
              {heading.name} {getSortDirectionIndicator(heading.value)}
            </TableCell>
          ))}
        </TableHead>
        <TableBody>
          {sortedCases.map((recentCase) => (
            <TableRow
              hover
              key={recentCase.caseKey}
              onClick={() => {
                if (
                  user?.permissions?.includes('case-update') ||
                  user?.permissions?.includes('case-get-by-id')
                ) {
                  navigate(`/erp/cases/${recentCase?.caseKey}`);
                }
              }}>
              {displayColumns.map((column) => (
                <TableCell
                  key={column.value}
                  align="center"
                  sx={{
                    color:
                      column.value === 'status' &&
                      produceStatusColor(
                        displayColumnValue(recentCase, column.value)
                      ),
                    padding: '3px 0px 3px 16px',
                  }}>
                  {column.value === 'priority' ? (
                    <PriorityComponent
                      label={displayColumnValue(recentCase, column.value)}
                    />
                  ) : (
                    displayColumnValue(recentCase, column.value)
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardTable;
