import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import RestartAltIcon from '@material-ui/icons/RestartAlt';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { casesApi } from 'src/api/casesApi';
import { requestApi } from 'src/api/requestApi';
import RequestAdvanceSearch from 'src/components/widgets/modal/RequestAdvanceSearch';
import SearchBar from 'src/components/widgets/searchBar/SearchBar';
import RequestListView from 'src/components/widgets/tables/RequestListView';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import setTitle from 'src/utils/setTitle';
import AddLeave from './add-leave';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    btn: {
      width: '47px',
      height: '30px',
      padding: 0,
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      color: '#fff',
      marginLeft: '13px',
      '& .MuiButton-iconSizeMedium': {
        margin: '0',
      },
    },
    textFieldSelect: {
      width: '230px',
      marginLeft: '13px',
    },
    textFieldSelectRoot: {
      borderRadius: '4px',
      height: '38px',
    },
    tabButton: {
      width: '181px',
    },
    cardRoot: {
      borderRadius: '4px',
    },
    tabs: {
      '& .MuiTab-root': {
        backgroundColor: 'transparent',
        minWidth: '120px',
      },
      '& .Mui-selected': {
        backgroundColor: '#231F20',
        color: '#fff',
      },
    },
    tableHead: {
      '& .MuiTableCell-root': {
        textTransform: 'capitalize',
      },
    },
    tableRow: {
      '& :hover': {
        cursor: 'pointer',
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    ticketMain: {
      position: 'relative',
      '& .customCss': {
        padding: 0,
      },
      '& .filterArea': {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        '& .rdrCalendarWrapper': {
          position: 'absolute',
          zIndex: '999',
          right: '0',
          top: -20,
        },
      },
      '&  .MuiTableCell-head': {
        padding: ' 10px 8px',
      },
      '& .dropDowns': {
        display: 'flex',
        '& .buttonParents': {
          position: 'relative',
        },
        '&  .buttonParents .dropDownInner': {
          overflowY: 'auto',
          position: 'absolute',
          padding: '13px',
          height: '220px',
          width: '350px',
          background: '#fff',
          zIndex: '22',
          boxShadow: ' 0px 0px 8px 3px rgba(0, 0, 0, 0.25)',
          borderRadius: '2px',
          top: '100%',
          left: 0,
          '& .dropDownInnerRow': {
            '& span': {
              fontSize: '12px',
            },
            '& p': {
              margin: '0',
            },
            '& .status': {
              alignItems: 'center',
            },
            display: 'flex',
            alignItems: 'center',
          },
        },
        '& .buttonParents+.buttonParents': {
          marginLeft: '10px',
        },
        '& button img': {
          marginLeft: '20px',
        },
      },
      '& .MuiAutocomplete-root': {
        background: '#fff',
      },
    },
    addButton: {
      height: 40,
      width: '47px',
      borderRadius: '5px',
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '10px',
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    },
  })
);

function ViewRequests(props: any) {
  setTitle('ERP | Internal Requests');
  const { user } = useAuth();

  const { hiddenColumns } = props;
  const classes = useStyles();
  const { settings } = useSettings();
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const [bulkIds, setBulkIds] = useState<String>('');
  const [allData, setAllData] = useState<any>([]);
  const [page, setPage] = useState<any>(
    JSON.parse(localStorage.getItem('requestsPagePg'))?.casesPageNo || 0
  );
  const [pageSize, setPageSize] = useState<any>(
    JSON.parse(localStorage.getItem('requestsPagePg'))?.casesPageLimit || 25
  );
  const handlePage = (temp: number) => {
    setPage(temp);
  };
  const [totalCount, setTotalCount] = useState<number>(0);
  const handlePageSize = (temp: number) => {
    setPageSize(temp);
  };
  const [search, setSearch] = useState<any>(
    JSON.parse(localStorage.getItem('requestsPagePg'))?.casesSearch || ''
  );
  const handleSearch = (val) => {
    setSearch(val);
  };

  const handleAddBulkIds = (id: String) => {
    setBulkIds(id);
  };

  const handleRemoveBulkIds = (id: String) => {
    setBulkIds(id);
  };

  const toggleAdvancedSearch = () => {
    setShowAdvancedSearch(!showAdvancedSearch);
  };

  /** sort data */
  type SortDataTypes = {
    key: 'asc' | 'desc';
    summary: 'asc' | 'desc';
    category: 'asc' | 'desc';
    status: 'asc' | 'desc';
    createdBy: 'asc' | 'desc';
    createdAt: 'asc' | 'desc';
    updatedAt: 'asc' | 'desc';
  };
  const [selectedSort, setSelectedSort] = useState({
    key:
      JSON.parse(localStorage.getItem('requestsPagePg'))
        ?.requestsSelectedSortKey || '',
    value:
      JSON.parse(localStorage.getItem('requestsPagePg'))
        ?.requestsSelectedSortValue || '',
  });
  const [sortData, setSortData] = useState<SortDataTypes>({
    key:
      JSON.parse(localStorage.getItem('requestsPagePg'))?.requestsSortKey ||
      'asc',
    summary:
      JSON.parse(localStorage.getItem('requestsPagePg'))?.requestsSortSummary ||
      'asc',
    category:
      JSON.parse(localStorage.getItem('requestsPagePg'))
        ?.requestsSortCategory || 'asc',
    createdBy:
      JSON.parse(localStorage.getItem('requestsPagePg'))
        ?.requestsSortCreatedBy || 'asc',
    status:
      JSON.parse(localStorage.getItem('requestsPagePg'))?.requestsSortStatus ||
      'asc',
    createdAt:
      JSON.parse(localStorage.getItem('requestsPagePg'))
        ?.requestsSortCreatedAt || 'asc',
    updatedAt:
      JSON.parse(localStorage.getItem('requestsPagePg'))
        ?.requestsSortUpdatedAt || 'asc',
  });
  const toggleSortData = (fieldName: string) => {
    type SortStatusType = 'asc' | 'desc';
    let sortStatus: SortStatusType = 'asc';
    const initial: SortDataTypes = {
      key: 'asc',
      summary: 'asc',
      category: 'asc',
      status: 'asc',
      createdBy: 'asc',
      createdAt: 'asc',
      updatedAt: 'asc',
    };
    // eslint-disable-next-line default-case
    switch (fieldName) {
      case 'key':
        if (sortData.key === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          key: sortStatus,
        });
        break;
      case 'summary':
        if (sortData.summary === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          summary: sortStatus,
        });
        break;
      case 'category':
        if (sortData.category === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          category: sortStatus,
        });
        break;
      case 'status':
        if (sortData.status === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          status: sortStatus,
        });
        break;
      case 'createdAt':
        if (sortData.createdAt === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          createdAt: sortStatus,
        });
        break;
      case 'updatedAt':
        if (sortData.updatedAt === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          updatedAt: sortStatus,
        });
        break;
      case 'createdBy':
        if (sortData.createdBy === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          ...initial,
          createdBy: sortStatus,
        });
        break;
    }
  };
  /** advance filters */
  const [date, setDate] = React.useState(
    JSON.parse(localStorage.getItem('requestsPagePg'))?.requestsDate || {
      to: null,
      from: null,
    }
  );
  const [category, setCategory] = React.useState<any>(
    JSON.parse(localStorage.getItem('requestsPagePg'))?.requestsCategory || []
  );
  const [createdBy, setCreatedBy] = React.useState<any>(
    JSON.parse(localStorage.getItem('requestsPagePg'))?.requestsCreatedBy || []
  );
  const [status, setStatus] = React.useState<any>(
    JSON.parse(localStorage.getItem('requestsPagePg'))?.requestsStatus || []
  );
  const [hasAttachments, setHasAttachments] = React.useState<boolean>(
    JSON.parse(localStorage.getItem('requestsPagePg'))
      ?.requestsHasAttachments || false
  );
  const [drawer, setDrawer] = useState<boolean>(false);

  const addLeave = useCallback(
    async (open: boolean, event: any) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawer(open);
    },
    [drawer]
  );

  const handleCloseDrawer = () => {
    setDrawer(!drawer);
  };

  const getAllData = async () => {
    try {
      const fieldToColumnMap = {
        key: 'key',
        summary: 'summary',
        category: 'category',
        status: 'status',
        createdBy: 'createdBy',
        createdAt: 'createdAt',
        updatedAt: 'updatedAt',
        //   type: 'caseType.name',
      };

      const columnValue = fieldToColumnMap[selectedSort?.key] || 'defaultSort';
      const varr = {
        page,
        pageSize,
        search,
        category: category?.map((itm) => itm.id).toString(),
        createdBy: createdBy?.map((itm) => itm.id).toString(),
        status: status?.map((itm) => itm.id).toString(),
        hasAttachments,
        to: date?.to,
        from: date?.from,
        column: columnValue,
        sort: selectedSort?.value === 'asc' ? '1' : '-1',
      };
      if (selectedSort?.key === '' || selectedSort?.value === undefined) {
        delete varr.column;
        delete varr.sort;
      }
      const keys = Object.keys(varr);
      const values = Object.values(varr);

      // Create an array to store the non-empty key-value pairs
      const queryParams = [];
      for (let i = 0; i < values.length; i++) {
        if (values[i] !== '' && values[i] !== null && values[i] !== undefined) {
          queryParams.push(`${encodeURIComponent(keys[i])}=${values[i]}`);
        }
      }
      const queryString = queryParams.join('&');
      let response = await requestApi.advanceSearch(queryString);
      response = response?.data;

      setAllData(response?.data);
      setTotalCount(response?.totalRecords);
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setAllData([]);
        setTotalCount(0);
      }
    }
  };

  const deleteCaseById = async (id: String) => {
    try {
      const res = await casesApi.deleteCase(id);
      if (res?.status === 200) {
        toast.success('Case deleted successfully');
        getAllData();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (!user.role?.some((role) => role.identifier === 'it22_admin')) {
      window.location.href = '/admin/not-found';
    }
    getAllData();
    const requestsPagePg = {
      requestsPageLimit: pageSize,
      requestsPageNo: page,
      requestsSearch: search,
      requestsSortKey: sortData?.key,
      requestsSortSummary: sortData?.summary,
      requestsSortCategory: sortData?.category,
      requestsSortStatus: sortData?.status,
      requestsCategory: category,
      requestsCreatedBy: createdBy,
      requestsStatus: status,
      requestsHasAttachments: hasAttachments,
      requestsDate: date,
      requestsSelectedSortKey: selectedSort?.key,
      requestsSelectedSortValue: selectedSort?.value,
    };
    localStorage.setItem('requestsPagePg', JSON.stringify(requestsPagePg));
  }, [
    JSON.stringify(category),
    JSON.stringify(createdBy),
    JSON.stringify(status),
    hasAttachments,
    date?.from,
    date?.to,
    page,
    pageSize,
    search,
    sortData?.key,
    sortData?.summary,
    sortData?.category,
    sortData?.status,
    selectedSort?.key,
    selectedSort?.value,
  ]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}
        className={classes.ticketMain}>
        <Container
          maxWidth={settings.compact ? 'xl' : false}
          className={
            hiddenColumns?.customCss === 'customCss' ? 'customCss' : ''
          }>
          <Grid container justifyContent="space-between" spacing={3}>
            {hiddenColumns?.heading === false ? (
              ''
            ) : (
              <Grid item>
                <Typography color="textPrimary" variant="h3">
                  Requests
                </Typography>
              </Grid>
            )}
          </Grid>
          <Box sx={{ mt: 1 }}>
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <SearchBar onSearch={handleSearch} value={search} />

                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ height: 30, marginLeft: 1, fontWeight: 'normal' }}
                  onClick={toggleAdvancedSearch}>
                  Advanced Search
                </Button>

                {showAdvancedSearch && (
                  <RequestAdvanceSearch
                    _headingMain="Advanced Search"
                    // _isOpen={isOpen}
                    _setShowAdvancedSearch={setShowAdvancedSearch}
                    _handleSubmit={() => null}
                    _search={search}
                    _setSearch={handleSearch}
                    _category={category}
                    _setCategory={setCategory}
                    _createdBy={createdBy}
                    _setCreatedBy={setCreatedBy}
                    _status={status}
                    _setStatus={setStatus}
                    _hasAttachments={hasAttachments}
                    _setHasAttachments={setHasAttachments}
                    _date={date}
                    _setDate={setDate}
                    _setPage={setPage}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ height: 30, marginLeft: 1, fontWeight: 'normal' }}
                  onClick={(e) => addLeave(true, e)}>
                  Add Leave
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<RestartAltIcon />}
                  className={classes.btn}
                  onClick={() => {
                    localStorage.removeItem('requestsPagePg');
                    handlePageSize(10);
                    handlePage(0);
                    handleSearch('');
                    setSortData({
                      key: 'asc',
                      summary: 'asc',
                      category: 'asc',
                      status: 'asc',
                      createdAt: 'asc',
                      updatedAt: 'asc',
                      createdBy: 'asc',
                    });
                    setCategory([]);
                    setCreatedBy([]);
                    setStatus([]);
                    setHasAttachments(false);
                    setDate({ to: null, from: null });
                    setSelectedSort({ key: '', value: '' });
                  }}
                />

                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  className={classes.btn}
                  disabled={!bulkIds.length}
                  onClick={() => deleteCaseById(bulkIds)}
                  style={{ display: 'none' }}
                />
              </Grid>
            </Grid>

            <RequestListView
              data={allData}
              sortData={sortData}
              toggleSortData={toggleSortData}
              selectedSort={selectedSort}
              setSelectedSort={setSelectedSort}
              redirectUrl="/erp/internal-requests"
              editRouteUrl="/erp/internal-requests"
              page={page}
              limit={pageSize}
              handleLimit={handlePageSize}
              handlePage={handlePage}
              count={totalCount}
              bulkIds={bulkIds}
              handleAddBulkIds={handleAddBulkIds}
              handleRemoveBulkIds={handleRemoveBulkIds}
            />
          </Box>
        </Container>
        {drawer && (
          <AddLeave
            isOpen={drawer}
            addLeave={addLeave}
            closeDrawer={handleCloseDrawer}
            fetchData={getAllData}
          />
        )}
      </Box>
    </>
  );
}

export default ViewRequests;
