import { Box, Button, Grid } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { FC, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useOutletContext } from 'react-router-dom';
import { organizationApi } from 'src/api/organizationApi';
import { projectApi } from 'src/api/projectApi';
import { TableProps } from 'src/types/components/tableList.types';
import SearchBar from '../../widgets/searchBar/SearchBar';
import TableProjects from '../../widgets/tables/TableProjects';
import CreateProject from './CreateProject';
import SingleDeleteConfirmation from 'src/components/widgets/Dialog/SingleDeleteConfirmation';
import RestartAltIcon from '@material-ui/icons/RestartAlt';
import useAuth from 'src/hooks/useAuth';
import StartSprintDrawer from './StartSprintDrawer';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    btn: {
      width: '30px',
      height: '30px',
      padding: 0,
      minWidth: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      color: '#fff',
      marginLeft: '8px',
      marginBottom: '7px',
      '& .MuiButton-iconSizeMedium': {
        margin: '0',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '16px',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    activeButton: {
      background: 'rgba(7, 83, 0, 0.1)',
      color: 'rgba(29, 122, 21, 1)',
      width: '100px',
    },
    inActiveButton: {
      background: 'rgba(255, 161, 161, 0.3)',
      color: 'red',
      width: '100px',
    },
    table: {
      '& .MuiTableCell-head': {
        padding: ' 10px 8px',
      },
    },
    breadCrumbsContainer: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
    },
    tabsAreaProject: {
      margin: '16px 10px 0',
      '& .MuiTabs-flexContainer': {
        borderBottom: '1px solid #231F20',
      },
      '& .tabsArea': {
        margin: '16px 0 0',
        '& .MuiTab-root': {
          height: '32px',
          minHeight: 'inherit',
          margin: '0',
          minWidth: '105px',
        },
        '& hr': {
          border: 0,
          borderTop: '0.8px solid #E0E0E0',
          margin: '21px 0 0',
          borderWidth: '0.8px',
        },
        '& .MuiTabs-indicator ': {
          display: 'none',
        },
        "& .Mui-selected[aria-selected='true']": {
          background: '#FFF',
          color: '#231F20',
          border: '1px solid #231F20',
          borderBottom: '1px solid #fff',
          position: 'relative',
          top: 1,
        },
      },
    },
  })
);

const ProjectSection: FC<TableProps> = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const [count, setCount] = useState<number>(0);

  const [page, setPage] = useState<any>(
    JSON.parse(localStorage.getItem('projPagePg'))?.projPageNo || 0
  );
  const [limit, setLimit] = useState<any>(
    JSON.parse(localStorage.getItem('projPagePg'))?.projPageLimit || 25
  );
  const [search, setSearch] = useState<string>(
    JSON.parse(localStorage.getItem('projPagePg'))?.search || ''
  );
  const [bulkIds, setBulkIds] = useState<string>('');
  type SortDataTypes = {
    name: 'asc' | 'desc';
    description: 'asc' | 'desc';
    domain: 'asc' | 'desc';
    website: 'asc' | 'desc';
    cases: 'asc' | 'desc';
  };
  const [sortData, setSortData] = useState<SortDataTypes>({
    name: JSON.parse(localStorage.getItem('projPagePg'))?.name || 'asc',
    description:
      JSON.parse(localStorage.getItem('projPagePg'))?.description || 'asc',
    domain: JSON.parse(localStorage.getItem('projPagePg'))?.domain || 'asc',
    website: JSON.parse(localStorage.getItem('projPagePg'))?.website || 'asc',
    cases: JSON.parse(localStorage.getItem('projPagePg'))?.cases || 'asc',
  });
  const [column, setColumn] = useState<string>(
    JSON.parse(localStorage.getItem('projPagePg'))?.column || ''
  );
  const [sortVal, setSortVal] = useState<string>(
    JSON.parse(localStorage.getItem('projPagePg'))?.sort || ''
  );
  const { organizationID }: any = useOutletContext();
  const baseUrl = '/erp/crm/organization/details';
  const [projectData, setProjectData] = useState<any>([]);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [sprintDrawer, setSprintDrawer] = useState<boolean>(false);

  const [deleteDialogueVisible, setDeleteDialogueVisible] =
    useState<boolean>(false);

  const addProject: any = (open: boolean, event: any) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawer(open);
  };

  const startSprint = useCallback(
    async (open: boolean, event: any) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setSprintDrawer(open);
    },
    [sprintDrawer]
  );

  const getAllProjectsByOrg = async () => {
    try {
      const response = await organizationApi.getAllProjectbyOrg(
        organizationID,
        page,
        limit,
        search,
        column,
        sortVal
      );
      if (response?.status === 200) {
        setProjectData(response?.data?.data);
        setCount(response?.data?.totalRecords);
      }
      if (response?.status === 204) {
        toast.error('try again later');
        setProjectData([]);
        setCount(0);
      }
    } catch (error) {
      toast.error('try again later');
      setProjectData([]);
      setCount(0);
    }
  };

  const deleteProjectById = async (id: String) => {
    try {
      const res = await organizationApi.deleteProjByOrg(id);
      if (res?.status === 200) {
        toast.success('Project deleted successfully');
        getAllProjectsByOrg();
        setBulkIds('');
      } else if (res?.status === 204) {
        toast.error('Project Not Exists');
      }
    } catch (error) {
      toast.error('Internal Server Error');
    }
  };

  const toggleProjectVisibility = async (id: String) => {
    try {
      const res = await projectApi.changeProjectVisibility(`${id}`);
      if (res?.status === 200) {
        toast.success('Project updated successfully');
        getAllProjectsByOrg();
        setBulkIds('');
      } else if (res?.status === 204) {
        toast.error('Project Not Exists');
      }
    } catch (error) {
      toast.error('Internal Server Error');
    }
  };

  const handlePage = (temp: number) => {
    setPage(temp);
  };
  const handleLimit = (temp: number) => {
    setLimit(temp);
  };
  const handleCloseDrawer = () => {
    setDrawer(!drawer);
  };
  const handleCloseSprintDrawer = () => {
    setSprintDrawer(!sprintDrawer);
  };
  const handleAddBulkIds = (id: string) => {
    setBulkIds(id);
  };

  const handleRemoveBulkIds = (id: string) => {
    setBulkIds(id);
  };
  const handleSearch = (val) => {
    setSearch(val);
  };

  const handleProjectDelete = (id) => {
    deleteProjectById(id);
  };

  const handleProjectVisibility = (id) => {
    toggleProjectVisibility(id);
  };
  const toggleSortData = (fieldName: string) => {
    type SortStatusType = 'asc' | 'desc';
    let sortStatus: SortStatusType = 'asc';
    // eslint-disable-next-line default-case
    switch (fieldName) {
      case 'name':
        if (sortData.name === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          name: sortStatus,
          description: 'asc',
          domain: 'asc',
          website: 'asc',
          cases: 'asc',
        });
        break;
      case 'description':
        if (sortData.description === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          name: 'asc',
          description: sortStatus,
          domain: 'asc',
          website: 'asc',
          cases: 'asc',
        });
        break;
      case 'domain':
        if (sortData.domain === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          name: 'asc',
          description: 'asc',
          domain: sortStatus,
          website: 'asc',
          cases: 'asc',
        });
        break;
      case 'website':
        if (sortData.website === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          name: 'asc',
          description: 'asc',
          domain: 'asc',
          website: sortStatus,
          cases: 'asc',
        });
        break;
      case 'cases':
        if (sortData.cases === 'asc') {
          sortStatus = 'desc';
        }
        setSortData({
          name: 'asc',
          description: 'asc',
          domain: 'asc',
          website: 'asc',
          cases: sortStatus,
        });
        break;
    }
  };
  const getAllSortedData = async (fieldName: string, value: string) => {
    let columnValue = '';
    const fieldToColumnMap = {
      name: 'name',
      description: 'description',
      domain: 'domain',
      website: 'website',
      cases: 'totalCases',
    };

    columnValue = fieldToColumnMap[fieldName] || 'defaultSort';
    setColumn(columnValue);
    setSortVal(value);
  };
  const columns: Array<any> = [
    {
      id: 'name',
      name: 'Name',
      value: 'name',
      isSort: true,
      toggleSortData,
      sortName: 'name',
      direction: sortData?.name,
    },
    {
      id: 'description',
      name: 'Description',
      value: 'description',
      isSort: true,
      sortName: 'description',
      toggleSortData,
      direction: sortData?.description,
    },
    {
      id: 'domain',
      name: 'Domain',
      value: 'domain',
      isSort: true,
      sortName: 'domain',
      toggleSortData,
      direction: sortData?.domain,
    },
    {
      id: 'website',
      name: 'Website',
      value: 'website',
      isSort: true,
      sortName: 'website',
      toggleSortData,
      direction: sortData?.website,
    },
    {
      id: 'cases',
      name: 'Cases',
      value: 'totalCases',
      sortName: 'totalCases',
      isSort: true,
      toggleSortData,
      direction: sortData?.cases,
    },
    {
      id: 'visibility',
      name: 'Visibility',
      value: 'isVisibleToClient',
      isSort: false,
    },
  ];
  useEffect(() => {
    if (
      !user?.permissions?.includes('organization-update') &&
      !user?.permissions?.includes('organization-get-by-id') &&
      !user?.permissions?.includes('organization-project-tab-read') &&
      !user?.permissions?.includes('organization-project-create') &&
      !user?.permissions?.includes('organization-project-get-all') &&
      !user?.permissions?.includes('organization-project-update') &&
      !user?.permissions?.includes('organization-project-get-by-id') &&
      !user?.permissions?.includes('organization-project-delete') &&
      !user?.permissions?.includes('organization-project-bulk-deactivate') &&
      !user?.permissions?.includes('organization-project-toggle-status') &&
      !user?.permissions?.includes('organization-project-filter-tabs-read')
    ) {
      window.location.href = '/admin/not-found';
    } else {
      getAllProjectsByOrg();
      const projPagePg = {
        projPageLimit: limit,
        ProjPageNo: page,
        search,
        name: sortData?.name,
        description: sortData?.description,
        domain: sortData?.domain,
        website: sortData?.website,
        cases: sortData?.cases,
        column,
        sort: sortVal,
      };
      localStorage.setItem('projPagePg', JSON.stringify(projPagePg));
    }
  }, [
    page,
    limit,
    search,
    sortData?.name,
    sortData?.description,
    sortData?.domain,
    sortData?.website,
    sortData?.cases,
    column,
    sortVal,
  ]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
      className={classes.table}>
      <Grid container>
        <Grid item xs={12} md={4}>
          {user?.permissions?.includes('organization-project-get-all') && (
            <SearchBar
              placeholderData="Search"
              margins="0 0 8px"
              onSearch={handleSearch}
              value={search}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
          {' '}
          <Button
            variant="contained"
            color="secondary"
            sx={{ height: 30, marginLeft: 1, mb: 1, fontWeight: 'normal' }}
            onClick={(e) => startSprint(true, e)}>
           Create Sprint
          </Button>
          {user?.permissions?.includes('organization-project-create') && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddCircleIcon />}
              className={classes.btn}
              onClick={(e) => addProject(true, e)}
            />
          )}
          <Button
            variant="contained"
            color="secondary"
            startIcon={<RestartAltIcon />}
            className={classes.btn}
            onClick={() => {
              localStorage.removeItem('projPagePg');
              handleLimit(25);
              handlePage(0);
              handleSearch('');
              setSortVal('');
              setColumn('');
              setSortData({
                name: 'asc',
                description: 'asc',
                domain: 'asc',
                website: 'asc',
                cases: 'asc',
              });
            }}
          />
          {user?.permissions?.includes('organization-project-delete') && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              className={classes.btn}
              disabled={!bulkIds.length}
              onClick={() => setDeleteDialogueVisible(true)}
            />
          )}
        </Grid>
      </Grid>

      {drawer && user?.permissions?.includes('organization-project-create') && (
        <CreateProject
          isOpen={drawer}
          addProject={addProject}
          closeDrawer={handleCloseDrawer}
          getAllProjectsByOrg={getAllProjectsByOrg}
        />
      )}

      {user?.permissions?.includes('organization-project-get-all') && (
        <TableProjects
          data={projectData}
          handlePage={handlePage}
          handleLimit={handleLimit}
          count={count}
          page={page}
          limit={limit}
          bulkIds={bulkIds}
          handleAddBulkIds={handleAddBulkIds}
          handleRemoveBulkIds={handleRemoveBulkIds}
          allowedColumns={columns}
          column={column}
          sortVal={sortVal}
          redirectUrl={baseUrl}
          editRouteUrl={`${baseUrl}/${organizationID}`}
          isEditAllowed
          getAllSortedData={getAllSortedData}
          handleProjectVisibility={handleProjectVisibility}
        />
      )}
      <SingleDeleteConfirmation
        isOpen={deleteDialogueVisible}
        setDeleteDialogueVisible={setDeleteDialogueVisible}
        selectedItem={bulkIds}
        handleDelete={handleProjectDelete}
        text="Do you want to delete this project ?"
      />
      {sprintDrawer && (
        <StartSprintDrawer
          isOpen={sprintDrawer}
          closeDrawer={handleCloseSprintDrawer}
          getAllData={getAllProjectsByOrg}
        />
      )}
    </Box>
  );
};

export default ProjectSection;
