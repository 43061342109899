import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';

interface DeleteLogTimeModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: any;
  item: { createdBy: { name: string } } | null;
}

const DeleteLogTimeModal: React.FC<DeleteLogTimeModalProps> = ({
  open,
  onClose,
  onConfirm,
  item,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Are you sure you want to delete the log for {item?.createdBy?.name}?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={onConfirm} color="secondary" variant="contained">
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteLogTimeModal;
