import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
  // Typography,
} from '@material-ui/core';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import setTitle from 'src/utils/setTitle';
// import gtm from '../../lib/gtm';
import DashboardTable from 'src/components/widgets/tables/tableDashboard';
import { dashboardApi } from 'src/api/dashboardApi';
import type { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { casesApi } from 'src/api/casesApi';
import { height, padding } from '@material-ui/system';
import hexToRgbA from 'src/utils/hexToRgbA';
import { PieCustomLegend } from './PieCustomLegend';
import { uniqBy } from 'lodash';
import { reportingApi } from 'src/api/reporting';
import moment from 'moment';
// import StackedBarChart from 'src/components/widgets/charts/StackedBarChart';
// import PieChart from 'src/components/widgets/charts/PieChart';

const Overview: FC = () => {
  setTitle('ERP | Dashboard');
  const navigate = useNavigate();
  const { user } = useAuth();
  const [caseType, setCaseType] = useState(1);
  const [totalData, setTotalData] = useState({
    totalProjects: 0,
    activeUsers: 0,
  });
  const [recentCases, setRecentCases] = useState([]);
  const [casesCount, setCasesCount] = useState({});
  const [totalCasesCount, setTotalCasesCount] = useState<any>({
    'To Do': 0,
    'In Progress': 0,
    Closed: 0,
    'Not Approved': 0,
    'Waiting For Client': 0,
  });
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [organizations, setOrganizations] = useState([]); // Store the autocomplete options
  const [isPreviousDataChecked, setIsPreviousDataChecked] = useState(false);
  const [sprints, setSprints] = useState([]); // Store fetched sprints
  const [selectedSprint, setSelectedSprint] = useState(null);
  const [loadingSprints, setLoadingSprints] = useState(false);
  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
  const smallScreen = useMediaQuery('(min-width:900px) and (max-width:1730px)');

  // Refs for focus state
  const orgFocusRef = useRef(false);
  const sprintFocusRef = useRef(false);
  const useStyles = makeStyles<Theme>(() =>
    createStyles({
      root: {
        fontFamily: 'Quicksand',
        '& h1': {
          color: '#000',
          fontSize: '20px',
          fontWeight: '700',
          lineHeight: '116.7%',
          margin: 0,
        },
        '& p': {
          margin: 0,
        },
      },
      casesCard: {
        // height: '277px',
        '& .content': {
          display: 'flex',
          '& .InnerLeft': {
            width: '40%',
            paddingTop: '30px',
          },
          '& .InnerRight': {
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
            gap: '50px',
            '& .rightUpper': {
              display: 'flex',
              gap: '1.59vw',
              '& h1': {
                textAlign: 'center',
                color: '#50CD89',
                fontSize: '47px',
                fontWeight: 700,
              },
              '& p': {
                color: '#000',
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: 600,
              },
            },
            '& .rightLower': {
              '& p': {
                color: '#000',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
              },
            },
          },
        },
      },
      formControl: {
        height: '30px',
        '& .MuiOutlinedInput-root': {
          height: '30px',
        },
        '& .MuiSelect-select': {
          padding: '3.5px 14px',
        },
      },
      recentCasesCard: {
        height: '551px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '4px',
        background: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        '& h1': {
          padding: '8px 5px 10px 16px',
        },
        '& .content': {
          flexGrow: 1,
          overflowY: 'auto',
        },
        '& .subtitle': {
          paddingBottom: '10px',
          fontSize: '10px',
          textTransform: 'capitalize',
          fontStyle: 'italic',
        },
      },
      organizationsCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& .content': {
          display: 'flex',
          flexGrow: '1',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '60px',
          '& h2': {
            marginTop: '5px',
            marginBottom: '0px',
            color: '#16192C',
            fontSize: '20px',
            fontWeight: 600,
          },
          '& p': {
            marginTop: '15px',
            color: '#7A7A9D',
            textAlign: 'center',
            fontSize: '14px',
            fontWeight: 400,
          },
        },
      },
      upcomingMeetingsCard: {
        height: '258px',
        display: 'flex',
        flexDirection: 'column',
        '& .noOfMeetings': {
          color: '#C22027',
          fontSize: '47px',
          fontWeight: 700,
        },
        '& .content': {
          display: 'flex',
          flexGrow: '1',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflowY: 'auto',
          '& .meetings': {
            '& .meetingDescription': {
              marginTop: '20px',
            },
          },
        },
      },
      upcomingEventsCard: {
        height: '258px',
        display: 'flex',
        flexDirection: 'column',
        '& .noOfEvents': {
          color: '#C22027',
          fontSize: '47px',
          fontWeight: 700,
        },
        '& .content': {
          display: 'flex',
          flexGrow: '1',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflowY: 'auto',
          '& .events': {
            '& .eventsDescription': {
              marginTop: '20px',
            },
          },
        },
      },
      card: {
        borderRadius: '4px',
        background: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        padding: '20px 16px',
      },
      customBullet: {
        height: '5px',
        width: '10px',
        borderRadius: '10px',
        alignSelf: 'center',
        marginRight: '11px',
      },
    })
  );

  const [pieChartData, setPieChartData] = useState({
    series: [0, 0, 0, 0, 0],
    options: {
      chart: {
        width: 100,
      },
      stroke: {
        width: 0,
      },
      labels: [
        'To Do',
        'In Progress',
        'Closed',
        'Not Approved',
        'Waiting For Client',
      ],
      tooltip: {
        enabled: false,
        fillSeriesColor: true,
        style: {
          fontSize: '14px',
          color: '#a9f3df',
        },
      },
      plotOptions: {
        pie: {
          customScale: 1,
          dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10,
          },
          donut: {
            labels: {
              show: true,
              name: { show: false },
              value: {
                color: '#27272E',
                fontFamily: 'Quicksand',
                fontSize: '28px',
                fontWeight: 600,
              },
              total: {
                show: true,
                label: 'Total',
                fontSize: '10px',
                fontFamily: 'Arial, sans-serif',
                color: 'red',
              },

              formatter(w) {
                return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      fill: {
        colors: [
          hexToRgbA('#c22027', 1),
          hexToRgbA('#c22027', 0.8),
          hexToRgbA('#c22027', 0.6),
          hexToRgbA('#c22027', 0.4),
          hexToRgbA('#c22027', 0.2),
        ],
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  });

  const [barChartData, setBarChartData] = useState<any>({
    series: [
      {
        name: 'To Do',
        data: [],
      },
      {
        name: 'In Progress',
        data: [],
      },
      {
        name: 'Closed',
        data: [],
      },
      {
        name: 'Not Approved',
        data: [],
      },
      {
        name: 'Waiting For Client',
        data: [],
      },
    ],
    options: {
      colors: [
        hexToRgbA('#c22027', 1),
        hexToRgbA('#c22027', 0.8),
        hexToRgbA('#c22027', 0.6),
        hexToRgbA('#c22027', 0.4),
        hexToRgbA('#c22027', 0.2),
      ],
      grid: {
        strokeDashArray: 7,
        show: true,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '10px',
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: [
          'ToDo'.split(' '),
          'In Progress'.split(' '),
          'Closed'.split(' '),
          'Not Approved'.split(' '),
          'Waiting For Client'.split(' '),
        ],
        axisBorder: {
          show: false,
        },
        labels: {
          rotate: 0,
          // hideOverlappingLabels: true,

          style: {
            fontFamily: 'Quicksand',
            fontSize: '12px',
            fontWeight: 400,
            colors: '#7A7A9D',
          },
        },
        axisTicks: { show: false },
      },
      legend: {
        show: false,
      },
      yaxis: {
        labels: {
          style: {
            fontFamily: 'Quicksand',
            fontSize: '12px',
            fontWeight: 400,
            colors: '#7A7A9D',
          },
          formatter: (value) => Math.round(value).toString(),
        },
      },
      fill: {
        opacity: 1,
      },
    },
  });

  const [approvedCasesConfig, setApprovedCasesConfig] = useState({
    series: [
      {
        name: 'Approved Cases',
        data: [],
      },
    ],
    options: {
      ...barChartData.options, // Use the same options as barChartData
      xaxis: {
        ...barChartData.options.xaxis,
        categories: [],
      },
    },
  });

  const processApprovedCasesData = (data, isPreviousData = false) => {
    const seriesData = isPreviousData
      ? data.map((item) => item.approvedCount)
      : data.map((item) => item.approvedCases);

    const series = [
      {
        name: 'Approved Cases',
        data: seriesData,
      },
    ];

    const categories = isPreviousData
      ? data.map((item) => item.sprintName)
      : data.map((item) => moment(item.date).format('MMM DD'));

    return {
      series,
      options: {
        ...approvedCasesConfig.options,
        xaxis: {
          ...approvedCasesConfig.options.xaxis,
          categories,
          title: {
            text: isPreviousData ? 'Sprints' : 'Date',
          },
        },
      },
    };
  };

  const processDataForBarChart = (data, isPreviousData = false) => {
    if (isPreviousData) {
      const categories = Object.keys(data);
      const series = [
        {
          name: 'To Do',
          data: categories.map((key) => data[key]['To Do'] || 0),
        },
        {
          name: 'In Progress',
          data: categories.map((key) => data[key]['In Progress'] || 0),
        },
        {
          name: 'Closed',
          data: categories.map((key) => data[key].Closed || 0),
        },
        {
          name: 'Not Approved',
          data: categories.map((key) => data[key]['Not Approved'] || 0),
        },
        {
          name: 'Waiting For Client',
          data: categories.map((key) => data[key]['Waiting For Client'] || 0),
        },
      ];

      return {
        series,
        options: {
          ...barChartData.options,
          xaxis: {
            categories, // Use the sprint identifiers as categories
          },
        },
      };
    }

    return {
      series: [
        {
          name: 'To Do',
          data: [data['To Do'], null, null, null, null],
        },
        {
          name: 'In Progress',
          data: [null, data['In Progress'], null, null, null],
        },
        {
          name: 'Closed',
          data: [null, null, data.Closed, null, null],
        },
        {
          name: 'Not Approved',
          data: [null, null, null, data['Not Approved'], null],
        },
        {
          name: 'Waiting For Client',
          data: [null, null, null, null, data['Waiting For Client']],
        },
      ],
      options: {
        ...barChartData.options,
        xaxis: {
          categories: [
            'ToDo',
            'In Progress'.split(' '),
            'Closed',
            'Not Approved'.split(' '),
            'Waiting For Client'.split(' '),
          ],
        },
      },
    };
  };

  const processDataForPieChart = (data, isPreviousData = false) => {
    const cumulativeData = {
      'To Do': 0,
      'In Progress': 0,
      Closed: 0,
      'Not Approved': 0,
      'Waiting For Client': 0,
    };

    if (isPreviousData) {
      Object.keys(data).forEach((key) => {
        cumulativeData['To Do'] += data[key]['To Do'] || 0;
        cumulativeData['In Progress'] += data[key]['In Progress'] || 0;
        cumulativeData.Closed += data[key].Closed || 0;
        cumulativeData['Not Approved'] += data[key]['Not Approved'] || 0;
        cumulativeData['Waiting For Client'] +=
          data[key]['Waiting For Client'] || 0;
      });
    } else {
      cumulativeData['To Do'] += data['To Do'] || 0;
      cumulativeData['In Progress'] += data['In Progress'] || 0;
      cumulativeData.Closed += data.Closed || 0;
      cumulativeData['Not Approved'] += data['Not Approved'] || 0;
      cumulativeData['Waiting For Client'] += data['Waiting For Client'] || 0;
    }
    return {
      ...pieChartData,
      series: [
        cumulativeData['To Do'],
        cumulativeData['In Progress'],
        cumulativeData.Closed,
        cumulativeData['Not Approved'],
        cumulativeData['Waiting For Client'],
      ],
    };
  };

  const updateCasesOverviewCard = (data) => {
    const cumulativeData = {
      'To Do': 0,
      'In Progress': 0,
      Closed: 0,
      'Not Approved': 0,
      'Waiting For Client': 0,
    };

    Object.keys(data).forEach((key) => {
      cumulativeData['To Do'] += data[key]['To Do'] || 0;
      cumulativeData['In Progress'] += data[key]['In Progress'] || 0;
      cumulativeData.Closed += data[key].Closed || 0;
      cumulativeData['Not Approved'] += data[key]['Not Approved'] || 0;
      cumulativeData['Waiting For Client'] +=
        data[key]['Waiting For Client'] || 0;
    });

    setCasesCount(cumulativeData);
    setTotalCasesCount(cumulativeData);
  };

  const getCasesCount = async (val) => {
    try {
      if (val?.startDate && val?.endDate) {
        const response = await dashboardApi.getWeeklyCaseCount(
          val.startDate,
          val.endDate,
          selectedOrganization.id
        );
        if (response?.status === 200) {
          setCasesCount(response.data);
          setTotalCasesCount(response.data);

          const barChartProcessedData = processDataForBarChart(response.data);
          setBarChartData(barChartProcessedData);

          const pieChartProcessedData = processDataForPieChart(response.data);

          setPieChartData(pieChartProcessedData);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const getPreviousSprintData = async () => {
    try {
      if (selectedOrganization) {
        const response = await dashboardApi.getOverallCaseCount(
          selectedOrganization.id
        ); // Replace with the correct API call
        if (response?.status === 200) {
          const barChartProcessedData = processDataForBarChart(
            response.data,
            true
          );
          setBarChartData(barChartProcessedData);
          const pieChartProcessedData = processDataForPieChart(
            response.data,
            true
          );
          setPieChartData(pieChartProcessedData);

          updateCasesOverviewCard(response.data);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const classes = useStyles();

  // const getCasesData = async () => {
  //   try {
  //     const response = await dashboardApi.getCasesData();
  //     if (response?.status === 200) {
  //       setCasesData({
  //         open: response?.data?.open,
  //         closed: response?.data?.closed,
  //         inProgress: response?.data?.inProgress,
  //       });
  //       setChartSeries([
  //         response?.data?.open,
  //         response?.data?.inProgress,
  //         response?.data?.closed,
  //       ]);
  //     }
  //   } catch (error) {
  //     if (error?.response?.status !== 401) {
  //       toast.error(error?.response?.data?.message);
  //     }
  //   }
  // };

  const fetchApprovedCases = async () => {
    try {
      if (selectedOrganization && selectedSprint) {
        const response = await dashboardApi.getSprintApprovedCases(
          selectedSprint.startDate,
          selectedSprint.endDate,
          selectedOrganization.id
        );
        if (response?.status === 200) {
          const processedData = processApprovedCasesData(response.data, false);
          setApprovedCasesConfig(processedData);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const fetchPreviousSprintApprovedCases = async () => {
    try {
      if (selectedOrganization) {
        const response = await dashboardApi.getOverallApprovedCases(
          selectedOrganization.id
        );
        if (response?.status === 200) {
          const processedData = processApprovedCasesData(response.data, true);
          setApprovedCasesConfig(processedData);
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const getRecentCases = async () => {
    try {
      // let queryParams = `page=${page}&pageSize=${pageSize}`;
      // if (caseType === 0) {
      //   // created-by-me
      //   queryParams = `${queryParams}&createdBy=${user?.id}`;
      // } else if (caseType === 2) {
      //   // assigned-to-me
      //   queryParams = `${queryParams}&assignee=${user?.id}`;
      // }
      let res;
      if (isPreviousDataChecked && selectedOrganization) {
        res = await dashboardApi.getOverallCases(selectedOrganization.id, {
          assignee: caseType === 2 ? true : null,
          createdBy: caseType === 0 ? true : null,
        });
      } else if (selectedOrganization && selectedSprint) {
        res = await dashboardApi.getSprintCases(
          selectedSprint.startDate,
          selectedSprint.endDate,
          selectedOrganization.id,
          {
            assignee: caseType === 2 ? true : null,
            createdBy: caseType === 0 ? true : null,
          }
        );
      }
      if (res?.status === 200) {
        // setRecentCases(res?.data?.data.splice(5, 9));
        setRecentCases(res?.data);
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const getTotalOrganizationsData = async () => {
    try {
      if (selectedOrganization) {
        const response = await dashboardApi.getUsersandProjectsinOrganization(
          selectedOrganization.id
        );
        if (response?.status === 200) {
          setTotalData({
            totalProjects: response?.data?.projects,
            activeUsers: response?.data?.activeUsers,
          });
        }
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const fetchOrganizations = async () => {
    setLoadingAutocomplete(true);
    try {
      const response = await reportingApi.getOrganizations();
      setOrganizations(response?.data || []);
    } catch (error) {
      console.error('Failed to fetch billing accounts:', error);
    }
    setLoadingAutocomplete(false);
  };

  // Fetch sprints for the selected organization based on sprint search term
  const fetchOrganizationSprints = async (organizationForSprint) => {
    setLoadingSprints(true);
    try {
      const response = await reportingApi.getOrganizationsSprints({
        orgId: organizationForSprint?.id,
      });
      const uniqueSprintNames = uniqBy(
        response.data,
        (item: any) => item?.name
      );

      setSprints(uniqueSprintNames || []);
      // Set the first sprint as default if available
      if (response?.data?.length === 0) {
        setSelectedSprint(null);
      }
      if (response?.data?.length > 0) {
        setSelectedSprint(response.data[0]); // Automatically set the first sprint as selected
      }
    } catch (error) {
      console.error('Failed to fetch organization sprints:', error);
    }
    setLoadingSprints(false);
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  // Fetch sprints when the selected organization changes
  useEffect(() => {
    if (selectedOrganization) {
      fetchOrganizationSprints(selectedOrganization); // Fetch sprints based on selected organization
      getTotalOrganizationsData();
    }
  }, [selectedOrganization]);

  useEffect(() => {
    const defaultOrg = organizations.find(
      (org) => org.id === '64e4d2c228dea22ee9d5245f'
    );

    setSelectedOrganization(defaultOrg);
  }, [organizations]);

  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: ['#50CD89', '#D9DADE', '#3E97FF'],
    dataLabels: {
      enabled: false,
    },
    labels: ['To do', 'In Progress', 'Closed'],
    legend: {
      show: false,
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '80%',
          labels: {
            show: true,
            value: {
              show: true,
              offsetY: -10,
              color: '#000',
              // fontSize: '0.83vw',
              fontWeight: 500,
              fontFamily: 'Quicksand',
            },
            total: {
              show: true,
              label: '',
              color: '#000',
              // fontSize: '0.83vw',
              fontWeight: 500,
              fontFamily: 'Quicksand',
              showAlways: true,
              formatter(w) {
                const totalCases = w.globals.seriesTotals.reduce(
                  (a, b) => a + b,
                  0
                );
                return `${parseInt(totalCases.toFixed(1), 10)} cases`;
              },
            },
          },
        },
      },
    },
  };

  function formatNumberWithLeadingZero(number) {
    if (number === 0) {
      // Special case for zero
      return '0';
    }
    if (number < 10) {
      return `0${number.toString()}`;
    }
    return number.toString();
  }

  const handleCheckboxChange = (e) => {
    setIsPreviousDataChecked(e.target.checked);
  };

  useEffect(() => {
    if (!selectedSprint) {
      const data = {
        'Not Approved': 0,
        'To Do': 0,
        Closed: 0,
        Declined: 0,
        'In Progress': 0,
        'Waiting For Client': 0,
        totalCases: 0,
      };
      setTotalCasesCount(data);
      const processedBarChartData = processDataForBarChart(data);
      const processedPieChartData = processDataForPieChart(data);
      setBarChartData(processedBarChartData);
      setPieChartData(processedPieChartData);
    }
    if (isPreviousDataChecked) {
      getPreviousSprintData();
    } else {
      getCasesCount(selectedSprint);
    }
  }, [selectedSprint, isPreviousDataChecked]);

  // const dataStackBarChart  = [
  //   {
  //     id: "To Do",
  //     name: "To Do",
  //     rank: 1,
  //     values: [5],
  //   },
  //   {
  //     id: "In Progress",
  //     name: "In Progress",
  //     rank: 3,
  //     values: [10],
  //   },
  //   {
  //     id: "Done",
  //     name: "Done",
  //     rank: 7,
  //     values: [20],
  //   },
  //   {
  //     id: "Waiting For Client",
  //     name: "Waiting For Client",
  //     rank: 4,
  //     values: [30],
  //   },
  //   {
  //     id: "Waiting For IT22",
  //     name: "Waiting For IT22",
  //     rank: 5,
  //     values: [10, 20, 30],
  //   },
  //   {
  //     id: "Pending",
  //     name: "Pending",
  //     rank: 2,
  //     values: [10, 20, 30],
  //   },
  //   {
  //     id: "Stale",
  //     name: "Stale",
  //     rank: null,
  //     values: [10, 20, 30],
  //   },
  //   {
  //     id: "Re-Opened",
  //     name: "Re-Opened",
  //     rank: 8,
  //     values: [10, 20, 30],
  //   },
  //   {
  //     id: "Resolution Planned",
  //     name: "Resolution Planned",
  //     rank: 6,
  //     values: [10, 20, 30],
  //   },
  //   {
  //     id: "Closed",
  //     name: "Closed",
  //     rank: 9,
  //     values: [10, 20, 30],
  //   },
  // ];
  // const dataPieChart = [
  //   { id: "gs", name: "General Support" },
  //   { id: "rs", name: "Redhat Support" },
  //   { id: "adm", name: "Administrative" },
  //   { id: "aps", name: "Application Support" },
  //   { id: "ab", name: "Application Bug" },
  //   { id: "afr", name: "Application Feature Request" },
  // ];
  useEffect(() => {
    // gtm.push({ event: 'page_view' });
    // getCasesData();
    getTotalOrganizationsData();
  }, []);

  useEffect(() => {
    if (isPreviousDataChecked) {
      fetchPreviousSprintApprovedCases();
    } else {
      fetchApprovedCases();
    }
  }, [selectedOrganization, selectedSprint, isPreviousDataChecked]);

  useEffect(() => {
    getRecentCases();
  }, [caseType, selectedOrganization, selectedSprint, isPreviousDataChecked]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          px: 2,
          py: 4,
        }}>
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          marginBottom={2}
          gap={2}>
          <Typography
            color="textPrimary"
            variant="h3"
            alignSelf="flex-start"
            flex={1}>
            Dashboard
          </Typography>
          <Autocomplete
            id="organization-autocomplete"
            options={organizations}
            getOptionLabel={(option) => option.name || ''}
            loading={loadingAutocomplete}
            value={selectedOrganization} // Controlled by state
            isOptionEqualToValue={(option, value) => option.id === value?.id} // Fix the comparison issue
            clearIcon={null}
            sx={{
              width: '20%',
            }}
            onChange={(event, newValue) => {
              setSelectedOrganization(newValue); // Update the selected organization
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Organization"
                variant="outlined"
                InputLabelProps={{
                  sx: {
                    top: (() => {
                      if (selectedOrganization) {
                        return '0px';
                      }
                      if (!orgFocusRef.current) {
                        return '-7px';
                      }
                      return '0px';
                    })(),
                  },
                }}
                onFocus={() => {
                  orgFocusRef.current = true;
                }}
                onBlur={() => {
                  orgFocusRef.current = false;
                }}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    paddingTop: '0px !important',
                    height: '40px',
                  },
                  endAdornment: (
                    <>
                      {loadingAutocomplete ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />

          {/* Autocomplete for selecting sprint */}
          {selectedOrganization && (
            <Autocomplete
              disabled={isPreviousDataChecked}
              id="sprint-autocomplete"
              options={sprints}
              getOptionLabel={(option) => option.name || ''}
              loading={loadingSprints}
              value={selectedSprint} // Bind the selected sprint
              defaultValue={sprints?.[0]}
              sx={{
                width: '20%',
              }}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              onChange={(event, newValue) => {
                setSelectedSprint(newValue); // Set the selected sprint
              }}
              clearIcon={null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sprint"
                  variant="outlined"
                  onFocus={() => {
                    sprintFocusRef.current = true;
                  }}
                  onBlur={() => {
                    sprintFocusRef.current = false;
                  }}
                  InputLabelProps={{
                    sx: {
                      top: (() => {
                        if (selectedSprint) {
                          return '0px';
                        }
                        if (!sprintFocusRef.current) {
                          return '-7px';
                        }
                        return '0px';
                      })(),
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      paddingTop: '0px !important',
                      height: '40px',
                    },
                    endAdornment: (
                      <>
                        {loadingSprints ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={isPreviousDataChecked}
                onChange={handleCheckboxChange}
                name="previousData"
                color="primary"
              />
            }
            label="Show Previous Sprint Data"
          />
        </Box>

        {/* <Typography variant="h2" sx={{ mb: 3 }}>
          {`Welcome, ${user?.name}`}
        </Typography> */}
        <Grid container spacing={2} className={classes.root}>
          {user?.permissions?.includes('dashboard-recent-cases-get-all') && (
            <Grid container item md={8}>
              <Grid
                item
                xs={12}
                marginBottom={2}
                className={`${classes.casesCard} ${classes.card}`}>
                <h1>Approved Cases</h1>
                <Chart
                  options={approvedCasesConfig.options}
                  series={approvedCasesConfig.series}
                  type="bar"
                  height={350}
                />
              </Grid>
              <Grid item xs={12} className={`${classes.recentCasesCard}`}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  pr="20px">
                  <Box display="flex" alignItems="flex-end">
                    <h1>Recent Cases</h1>
                    {isPreviousDataChecked && (
                      <p className="subtitle">(last five sprints)</p>
                    )}
                  </Box>
                  <FormControl className={classes.formControl}>
                    <Select
                      id="caseType-select"
                      value={caseType}
                      onChange={(e) => setCaseType(e.target.value)}
                      size="small"
                      sx={{ width: 'auto' }}>
                      <MenuItem value={1}>All</MenuItem>
                      <MenuItem value={0}>Created By Me</MenuItem>
                      <MenuItem value={2}>Assigned To Me</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <div className="content">
                  <DashboardTable recentCases={recentCases} />
                </div>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            item
            display="flex"
            alignItems="flex-start"
            alignContent="flex-start"
            spacing={2}
            md={4}>
            <Grid item xs={12}>
              {user?.permissions?.includes('dashboard-cases-read') && (
                <div className={`${classes.casesCard} ${classes.card}`}>
                  <Box>
                    <h1>Cases Overview</h1>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      <Chart
                        options={pieChartData.options}
                        series={pieChartData.series}
                        type="donut"
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-evenly',
                        }}>
                        <PieCustomLegend
                          name="To Do"
                          value={totalCasesCount['To Do']}
                          color={hexToRgbA('#c22027', 1)}
                        />
                        <PieCustomLegend
                          name="In Progress"
                          value={totalCasesCount['In Progress']}
                          color={hexToRgbA('#c22027', 0.8)}
                        />
                        <PieCustomLegend
                          name="Closed"
                          value={totalCasesCount.Closed}
                          color={hexToRgbA('#c22027', 0.6)}
                        />
                        <PieCustomLegend
                          name="Not Approved"
                          value={totalCasesCount['Not Approved']}
                          color={hexToRgbA('#c22027', 0.4)}
                        />
                        <PieCustomLegend
                          name="Waiting For Client"
                          value={totalCasesCount['Waiting For Client']}
                          color={hexToRgbA('#c22027', 0.2)}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Chart
                    options={barChartData.options}
                    series={barChartData.series}
                    type="bar"
                    height={250}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              {user?.permissions?.includes(
                'dashboard-organizations-counters-read'
              ) && (
                <div className={`${classes.organizationsCard} ${classes.card}`}>
                  <h1>{selectedOrganization?.name}</h1>
                  <div className="content">
                    <Box>
                      <p>Total Projects</p>
                      <h2>
                        {formatNumberWithLeadingZero(totalData.totalProjects)}
                      </h2>
                    </Box>
                    <Box>
                      <p>Active Users</p>
                      <h2>
                        {formatNumberWithLeadingZero(totalData.activeUsers)}
                      </h2>
                    </Box>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>

          {/* {(user?.permissions?.includes('dashboard-upcoming-meetings-counters-read') || user?.permissions?.includes('dashboard-upcoming-events-counters-read')) && (
          <Grid
            item
            container
            spacing={2}
            md={8}
          > */}
          {/* {user?.permissions?.includes('dashboard-upcoming-meetings-counters-read') && (
            <Grid
              item
              md={6}
            >
              <div
                className={`${classes.upcomingMeetingsCard} ${classes.card}`}
              >
                <h1>Upcoming Meetings</h1>
                <h1 className="noOfMeetings">{formatNumberWithLeadingZero(numberOfUpcMeetings)}</h1>
                <div className="content">
                  <div className="meetings">
                    {upcomingMeetings?.map((meeting) => (
                      <div className="meetingDescription">
                        <p style={{ fontWeight: 'bold' }}>{meeting?.title}</p>
                        <p>{`${meeting?.description?.replace(/<[^>]*>|&nbsp;/g, '').slice(0, 100)} ${meeting?.description?.length > 100 ? '...' : ''}`}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Grid>
            )} */}
          {/* {user?.permissions?.includes('dashboard-upcoming-events-counters-read') && (
              <Grid
                item
                md={6}
              >
                <div
                  className={`${classes.upcomingEventsCard} ${classes.card}`}
                >
                  <h1>Upcoming Events</h1>
                  <h1 className="noOfEvents">{formatNumberWithLeadingZero(numberOfUpcEvents)}</h1>
                  <div className="content">
                    <div className="events">
                      {upcomingEvents?.map((event) => (
                        <div className="eventsDescription">
                          <p style={{ fontWeight: 'bold' }}>{event?.title}</p>
                          <p>{`${event?.description?.replace(/<[^>]*>|&nbsp;/g, '').slice(0, 100)} ${event?.description?.length > 100 ? '...' : ''}`}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Grid>
              )} */}

          {/* </Grid>
          )} */}
          {/* <Grid
                item
                md={12}
              ><div className={` ${classes.card}`}>
               <StackedBarChart data={dataStackBarChart} width={1200} height={420}  title="Statuses"/>
               </div>
              </Grid>
              <Grid
                item
                md={12}
              ><div className={` ${classes.card}`}>
                 <h1>Case Types</h1>
               <PieChart data={dataPieChart} width={600} height={420} />
               </div>
              </Grid> */}
        </Grid>
      </Box>
    </>
  );
};

export default Overview;
