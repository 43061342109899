import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import parse from 'src/utils/parse';

class DashboardApi {
  async getUpcomingMeetings(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/dashboard/upcoming/meetings`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getUpcomingEvents(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/dashboard/upcoming/events`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getCasesData(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/dashboard/status/count`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getRecentCases(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/dashboard/recent/cases`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getTotalOrganizationsData(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/organization/records`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getWeeklyCaseCount(startDate, endDate, orgId): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/dashboard/cases/weeklyCount`, {
          params: {
            from: new Date(startDate).toISOString().split('T')[0],
            orgId,
            to: new Date(endDate).toISOString().split('T')[0],
          },
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getOverallCaseCount(orgId): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/dashboard/cases/overAll`, {
          params: {
            orgId,
          },
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getSprintCases(startDate, endDate, orgId, {assignee, createdBy}): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_CASES}/dashboard/sprintWise/recent/cases`,
          {
            params: {
              from: new Date(startDate).toISOString().split('T')[0],
              orgId,
              to: new Date(endDate).toISOString().split('T')[0],
              assignedToMe: assignee,
              createdByMe: createdBy
            },
          }
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getOverallCases(orgId, {assignee, createdBy}): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/dashboard/recent/overAll/cases`, {
          params: {
            orgId,
            assignedToMe: assignee,
            createdByMe: createdBy
        },
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getSprintApprovedCases(startDate, endDate, orgId): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_CASES}/dashboard/sprintWise/approved/cases`,
          {
            params: {
              from: new Date(startDate).toISOString().split('T')[0],
              orgId,
              to: new Date(endDate).toISOString().split('T')[0],
            },
          }
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getOverallApprovedCasesGroupByDate(orgId): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_CASES}/dashboard/approved/cases/groupedByDate`,
          {
            params: {
              orgId,
            },
          }
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getOverallApprovedCases(orgId): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_CASES}/dashboard/approved/cases/groupedBySprint`,
          {
            params: {
              orgId,
            },
          }
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getUsersandProjectsinOrganization(orgId): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/dashboard/organizational/count`, {
          params: {
            orgId,
          },
        })
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export const dashboardApi = new DashboardApi();
