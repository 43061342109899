import { isUndefined } from 'lodash';
import axios from 'src/utils/axios';
import parse from 'src/utils/parse';

class TeamsApi {
  async getAllTeams({page, pageSize, search}): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/team/paginatedData`,{
          params: {
            page,
            pageSize,
            search
          }
        } )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async addTeams(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CASES}/team`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async editTeam({id, payload}: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.REACT_APP_CASES}/team/${id}`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async changeTeamStatus(id:string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.REACT_APP_CASES}
/team/toggleStatus/${id}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getTeamsAutocomplete(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_CASES}/auto-complete/teams`
        )
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async updateEmployeeTeams(employeeId: string, payload: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_CASES}/team/teamMember/${employeeId}`, payload)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }

  async getEmployeeTeams(employeeId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_CASES}/team/employeeId/${employeeId}`)
        .then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            resolve(response);
          } else {
            reject(new Error('internal Server Error'));
          }
        })
        .catch(() => {
          reject(new Error('internal Server Error'));
        });
    });
  }
}

export const teamsApi = new TeamsApi();
